import { SubstrateService } from './substrate.service';
import { Observable } from 'rxjs/Observable';
import { CoatService } from './coat.service';
import { Product, ProductType, ProductCategory, Tracer, Unit, ProductSku, Coat } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class ProductService {
  constructor(private dataService: DataService, private coatService: CoatService, private substrateService: SubstrateService) {

    //product
    this.product_full_api_url = dataService.getUrl() + this.product_api_endpoint;
    this.productCustom_api_endpoint_full_api_url = dataService.getUrl() + this.productCustom_api_endpoint;

    //unit
    this.unit_full_api_url = dataService.getUrl() + this.unit_api_endpoint;

    //productType
    this.productType_full_api_url = dataService.getUrl() + this.productType_api_endpoint;

    //productCategory
    this.productCategory_full_api_url = dataService.getUrl() + this.productCategory_api_endpoint;
  }

  ////////////////////////////////////////////////
  // COMMON
  ///////////////////////////////////////////////
  public initialised = new BehaviorSubject(false);

  ////////////////////////////////////////////////
  // Product
  ///////////////////////////////////////////////
  private product_api_endpoint = '/product';
  private product_full_api_url;
  private productCustom_api_endpoint = '/product-custom';
  private productCustom_api_endpoint_full_api_url;
  public products: Product[] = [];
  private productMap = {};
  private product_initialised = new BehaviorSubject(false);

  ////////////////////////////////////////////////
  // Unit
  ///////////////////////////////////////////////
  private unit_api_endpoint = '/unit';
  private unit_full_api_url;
  public units: Unit[] = [];
  private unit_initialised = new BehaviorSubject(false);

  ////////////////////////////////////////////////
  // Product Type
  ///////////////////////////////////////////////
  private productType_api_endpoint = '/producttype';
  private productType_full_api_url;
  public productTypes: ProductType[] = [];
  private productType_initialised = new BehaviorSubject(false);

  ////////////////////////////////////////////////
  // Product Category
  ///////////////////////////////////////////////
  private productCategory_api_endpoint = '/productcategory';
  private productCategory_full_api_url;
  public productCategories: ProductCategory[] = [];
  private productCategory_initialised = new BehaviorSubject(false);


  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);


    ////////////////////////////////////////////////
    // Product
    ///////////////////////////////////////////////
    this.product_initialised.complete();
    this.product_initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.product_full_api_url).subscribe(response => {
      if (response) {
        this.products = [];

        //wait until substrates are loaded
        this.substrateService.initialised.distinctUntilChanged().subscribe(substrateState => {
          if (substrateState) {

            for (let item of response['data']) {
              let product: Product = new Product();
              product.id = item['id'];
              product.name = item['name'];
              product.code = item['code'];
              product.code_short = item['code_short'];
              product.unit = item['unit'];
              product.kg_conversion = item['kg_conversion'];
              product.is_kansai = item['is_kansai'];
              product.is_undercoat = item['is_undercoat'];
              product.manufacturer = item['manufacturer'];
              product.formula = item['formula'];

              //save the keys/placeholders for now. Will be fixed by the CLEAN-UP section
              let dummy_category = new ProductCategory();
              dummy_category.id = item['category'];
              product.category = dummy_category;

              let dummy_type = new ProductType();
              dummy_type.id = item['type'];
              product.type = dummy_type;

              let dummy_unit = new Unit();
              dummy_unit.id = item['unit'];
              product.unit = dummy_unit;

              if (item['available_in']) {
                let dummy_available = [];
                for (let ptype of item['available_in']) {
                  let dummy_ptype = new ProductType();
                  dummy_ptype.id = ptype;
                  dummy_available.push(dummy_ptype);
                }
                product.available_in = dummy_available;
              }

              if (item['skus']) {
                product.skus = [];

                for (let resSku of item['skus']) {
                  let sku = new ProductSku();
                  sku.code = resSku['code'];
                  sku.pack_size = resSku['pack_size'];

                  product.skus.push(sku);
                }

                product.skus = product.skus.sort((a: ProductSku, b: ProductSku) => {
                  if (a.pack_size > b.pack_size)
                    return 1;
                  if (a.pack_size < b.pack_size)
                    return -1;
                  return 0;
                });
              }

              if (item['substrates']) {
                product.substrates = [];

                for (let resSubstr of item['substrates']) {
                  let substrate = this.substrateService.getSubstrateOffline(resSubstr);
                  if (substrate) {
                    product.substrates.push(substrate);
                  }
                }
              }

              this.products.push(product);

              //add to map for faster access
              this.productMap["" + product.id] = product;
            }

            //loop again to fix trace tinters
            for (let item of response['data']) {
              if (item['trace_tinter_for']) {
                let product = this.getProductById(item['id']);
                let targetProduct = this.getProductById(item['trace_tinter_for']['product']);

                if (product && targetProduct) {
                  let tracer = new Tracer();
                  tracer.dilutionFactor = item['trace_tinter_for']['dilution'];
                  tracer.product = targetProduct;
                  product.tracer = tracer;

                  let tracerInverse = new Tracer();
                  tracerInverse.dilutionFactor = 1 / tracer.dilutionFactor;
                  tracerInverse.product = product;
                  targetProduct.tracers.push(tracerInverse);
                }
              }
            }

            this.product_initialised.next(true);
          }
        });
      }
    });

    ////////////////////////////////////////////////
    // Units
    ///////////////////////////////////////////////
    this.unit_initialised.complete();
    this.unit_initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.unit_full_api_url).subscribe(response => {
      if (response) {
        this.units = [];

        for (let item of response['data']) {
          let unit = new Unit();
          unit.id = item['id'];
          unit.name_plural = item['name_plural'];
          unit.name_singular = item['name_singular'];

          this.units.push(unit);
        }

        this.unit_initialised.next(true);
      }
    });


    ////////////////////////////////////////////////
    // Product Type
    ///////////////////////////////////////////////
    this.productType_initialised.complete();
    this.productType_initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.productType_full_api_url).subscribe(response => {
      if (response && response['data']) {
        this.productTypes = [];

        this.coatService.initialised.distinctUntilChanged().subscribe(state => {
          if (state) {
            for (let ptype of response['data']) {
              let product_type = new ProductType();
              product_type.id = ptype['id'];
              product_type.code = ptype['code'];
              product_type.name = ptype['name'];
              product_type.logo_url = ptype['logo_url'];

              product_type.coats_variant = [];
              for (let coat_id of ptype['coats_variant']) {
                product_type.coats_variant.push(this.coatService.getCoatById(coat_id));
              }

              product_type.coats_variant = product_type.coats_variant.sort((a: Coat, b: Coat) => {
                if (a.order > b.order)
                  return -1;
                if (a.order < b.order)
                  return 1;
                return 0;
              });

              this.productTypes.push(product_type);
            }

            this.productType_initialised.next(true);
          }
        });


      }
    });


    ////////////////////////////////////////////////
    // Product Category
    ///////////////////////////////////////////////
    this.productCategory_initialised.complete();
    this.productCategory_initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.productCategory_full_api_url).subscribe(response => {
      if (response && response['data']) {
        this.productCategories = [];

        for (let pcat of response['data']) {
          let product_category = new ProductCategory();
          product_category.id = pcat['id'];
          product_category.name = pcat['name'];

          this.productCategories.push(product_category);
        }

        this.productCategory_initialised.next(true);
      }
    });



    ////////////////////////////////////////////////
    // CLEAN-UP
    // Because Products, Types and Categories are interlinked and connot be loaded
    // through a normal chain. Ths section is going through each entity and fixing it.
    ///////////////////////////////////////////////
    this.unit_initialised.distinctUntilChanged().subscribe(unit_state => {
      if (unit_state) {
        this.productCategory_initialised.distinctUntilChanged().subscribe(category_state => {
          if (category_state) {
            this.productType_initialised.distinctUntilChanged().subscribe(type_state => {
              if (type_state) {
                this.product_initialised.distinctUntilChanged().subscribe(product_state => {
                  if (product_state) {
                    //link entries in products
                    for (let product of this.products) {
                      product.category = this.getProductCategoryById(product.category.id);
                      product.type = this.getProductTypeById(product.type.id);
                      product.unit = this.getUnitById(product.unit.id);

                      let available_in = []
                      for (let avProductType of product.available_in) {
                        available_in.push(this.getProductTypeById(avProductType.id));
                      }
                      product.available_in = available_in;

                      if (product.formula) {
                        for (let substrate in product.formula) {
                          for (let applicationTechnique in product.formula[substrate]) {
                            //check if components are present, if not, add an empty array
                            if (product.formula[substrate][applicationTechnique]['components']) {
                              for (let component of product.formula[substrate][applicationTechnique]['components']) {
                                component['category'] = this.getProductCategoryById(component['category']);
                                this.linkComponent(component, product.available_in);
                              }
                            }
                            else {
                              product.formula[substrate][applicationTechnique]['components'] = [];
                            }
                          }

                        }

                      }
                    }


                    /*
                    //link entries in productTypes
                    for (let product_type of this.productTypes) {
    
                      //loop over the formulas and replace every product key with the actual product object
                      if (product_type.formula) {
                        for (let system in product_type.formula) {
                          for (let coat in product_type.formula[system]) {
                            for (let substrate in product_type.formula[system][coat]) {
                              for (let component of product_type.formula[system][coat][substrate]['components']) {
                                //category
                                component['category'] = this.getProductCategoryById(component['category']);
                                this.linkComponent(component, [product_type]);
                              }
                            }
                          }
                        }
                      }
                    }
                    */

                    //crate the available coats
                    /*
                    this.coatService.initialised.distinctUntilChanged().subscribe(initstate => {
                      if (initstate) {
                        for (let ptype of this.productTypes) {
                          ptype.available_coats = {};
    
                          for (let coat of this.coatService.coats) {
                            if (coat.type in ptype.coat_filter && ptype.coat_filter[coat.type].indexOf(coat.id) >= 0) {
                              continue;
                            }
    
                            if (!(coat.type in ptype.available_coats)) {
                              ptype.available_coats[coat.type] = [];
                            }
    
                            ptype.available_coats[coat.type].push(coat);
                          }
                        }
                        //signal that all is done
                        this.initialised.next(true);
                      }
                    });*/
                    this.initialised.next(true);

                  }
                });
              }
            });
          }
        });
      }
    });

  }

  private linkComponent(component: object, product_types: ProductType[]) {
    //product filter
    let products = [];

    if (component['products'] && component['products'].length != 0) {
      for (let product of component['products']) {
        products.push(this.getProductById(product));
      }
    }
    else {
      for (let prod of this.products) {
        //find all products with the specified category
        if (!prod.category)
          console.log(prod.category);
        if (!component['category']) {
          console.log(component);
          console.log(component['category']);
        }

        if (prod.category.id == component['category'].id) {
          //find products that are available in current product type
          for (let param_type of product_types) {
            for (let ptype of prod.available_in) {
              if (ptype.id == param_type.id) {
                products.push(prod);
                break;
              }
            }
          }
        }
      }
    }

    component['products'] = products;
  }


  getProductById(productId): Product {
    let ret_pt = this.productMap["" + productId];

    //only if not found in map iterate
    //(to avoid issues when directly adding products)
    if (!ret_pt) {
      for (let prod of this.products) {
        //console.log(prod.id + " - " + productId);
        if (prod.id === productId) {
          ret_pt = prod;
        }
      }
    }

    return ret_pt;
  }

  getProductsByTypeIdAndCategoryId(typeId, categoryId): Product[] {
    let prods: Product[] = [];

    for (let p of this.products) {
      if (!p.type || !p.category)
        continue;

      if (p.type.id == typeId && p.category.id == categoryId)
        prods.push(p);
    }

    return prods;
  }

  getUndercoatProductsByTypeId(typeId): Product[] {
    let prods: Product[] = [];

    for (let p of this.products) {
      if (!p.type)
        continue;

      if (p.is_undercoat && p.type.id == typeId)
        prods.push(p);
    }

    return prods;
  }


  getProductTypeById(productTypeId: string) {
    let ret_pt = null;

    for (let ptype of this.productTypes) {
      if (ptype.id == productTypeId) {
        ret_pt = ptype;
      }
    }

    return ret_pt;
  }

  getProductCategoryById(productCategoryId: string) {
    let ret_cat = null;

    for (let pCat of this.productCategories) {
      if (pCat.id == productCategoryId) {
        ret_cat = pCat;
      }
    }

    return ret_cat;
  }

  getUnitById(unit_id: string): Unit {
    let unit = null;

    for (let u of this.units) {
      if (u.id == unit_id) {
        unit = u;
      }
    }

    return unit;
  }

  buildProductRequest(product: Product) {
    let requestBody = {
      'code': product.code,
      'category': product.category.id,
      'is_kansai': product.is_kansai,
      'name': product.name,
      'unit': product.unit.id
    };

    if (product.code_short)
      requestBody['code_short'] = product.code_short;

    if (product.type)
      requestBody['type'] = product.type.id;

    if (product.tinter_class)
      requestBody['tinter_class'] = product.tinter_class;

    if (product.manufacturer)
      requestBody['manufacturer'] = product.manufacturer;

    if (product.formula)
      requestBody['formula'] = product.formula;

    if (product.available_in) {
      requestBody['available_in'] = [];

      for (let ptype of product.available_in) {
        requestBody['available_in'].push(ptype.id);
      }
    }

    if (product.skus) {
      requestBody['skus'] = [];

      for (let sku of product.skus) {
        requestBody['skus'].push({
          'pack_size': sku.pack_size,
          'code': sku.code
        });
      }
    }


    return requestBody;
  }

  buildProduct(response) {
    let product = new Product();

    console.log(response);

    return product;
  }


  saveProduct(product: Product): Observable<Product> {
    let requestUrl;
    let request;
    let requestBody = this.buildProductRequest(product);

    if (product.is_kansai)
      requestUrl = this.product_full_api_url;
    else
      requestUrl = this.productCustom_api_endpoint_full_api_url;

    if (product.id) {
      request = this.dataService.putRequest(requestUrl + "/" + product.id, requestBody);
    }
    else {
      request = this.dataService.postRequest(requestUrl, requestBody);
    }

    return request.map(response => {
      return this.buildProduct(response);
    });
  }

  deleteProduct(product: Product): Observable<any> {
    let requestUrl;

    if (product.is_kansai)
      requestUrl = this.product_full_api_url;
    else
      requestUrl = this.productCustom_api_endpoint_full_api_url;

    requestUrl += "/" + product.id;

    return this.dataService.deleteRequest(requestUrl);
  }
}
