import { SubstrateService } from './substrate.service';
import { ProductService } from './product.service';
import { ColourMatchService } from './colour-match.service';
import { CountryService } from './country.service';
import { CacheService } from './cache.service';
import { CoatService } from './coat.service';
import { ColourService } from './colour.service';
import { PanelService } from './panel.service';
import { BatchService } from './batch.service';
import { VariantService } from './variant.service';
import { VehicleService } from './vehicle.service';
import { Observable } from 'rxjs/Observable';
import { Bodyshop, Job, JobPanel, ColourMatch, CarModel, JobPanelSurface, JobPanelCoat, JobCustomProduct } from '../../../model/model';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { DataService } from './data.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';

@Injectable()
export class JobService {
  private api_endpoint = '/job';
  private full_api_url;

  private api_endpoint_id_query = '/job-id-query';
  private full_api_url_id_query;

  private api_endpoint_duplicate = '/job-duplicate';
  private full_api_url_duplicate;


  private api_endpoint_query = '/jobs';
  private full_api_url_query;

  public currentJob: Job;

  private parameters_simple = [
    'id',
    'car_license_plate',
    'car_model_text',
    'car_chassis_number',
    'id_custom',
    'customer_id',
    'customer_name',
    'customer_phone',
    'comments',
    'car_year',
    'benchmark_amount'
  ];

  constructor(public dataService: DataService,
    public userService: UserService,
    public vehicleService: VehicleService,
    public variantService: VariantService,
    public colourService: ColourService,
    public batchService: BatchService,
    public panelService: PanelService,
    public coatService: CoatService,
    public countryService: CountryService,
    public cacheService: CacheService,
    public productService: ProductService,
    public substrateService: SubstrateService,
    public colourMatchService: ColourMatchService
  ) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
    this.full_api_url_id_query = dataService.getUrl() + this.api_endpoint_id_query;
    this.full_api_url_duplicate = dataService.getUrl() + this.api_endpoint_duplicate;
    this.full_api_url_query = dataService.getUrl() + this.api_endpoint_query;
  }

  getJob(jobId: number) {
    if (jobId) {
      return this.dataService.getRequest(this.full_api_url + "/" + jobId).map(response => {
        if (response) {
          let job = this.buildJob(response);

          this.getDuplicateJobIds(job).subscribe(result => {
            job.duplicate_children = result;
          });

          return job;
        }
      });
    }
  }

  getJobs(bodyshop?: Bodyshop) {
    if (!bodyshop) {
      bodyshop = this.userService.currentUser.currentBodyshop;
    }

    return this.dataService.getRequest(this.full_api_url + "/bodyshop/" + bodyshop.id).map(response => {
      let jobs: Job[] = undefined;

      if (response && response["data"]) {
        jobs = [];

        let response_jobs = response["data"];

        for (let response_job of response_jobs) {
          jobs.push(this.buildJob(response_job));
        }
      }

      return jobs;
    });
  }

  queryJobs(bodyshops: Bodyshop[], status: string, ts_from?: Date, ts_to?: Date): Observable<Job[]> {
    let requestBody = {
      'bodyshops': [],
      'status': status
    };

    if (ts_from)
      requestBody['ts_from'] = ts_from.toISOString();

    if (ts_to)
      requestBody['ts_to'] = ts_to.toISOString();

    for (let bshop of bodyshops) {
      requestBody['bodyshops'].push(bshop.id);
    }

    return this.dataService.postRequest(this.full_api_url_query, requestBody).map(response => {
      if (response && response['data']) {
        let jobs: Job[] = [];

        for (let resJob of response['data']) {
          jobs.push(this.buildJob(resJob));
        }

        return jobs;
      }
    });
  }

  queryJobIds(bodyshops: Bodyshop[], starts_with:string): Observable<Job[]> {
    let requestBody = {
      'bodyshops': [],
      'starts_with': starts_with
    };
    for (let bshop of bodyshops) {
      requestBody['bodyshops'].push(bshop.id);
    }

    return this.dataService.postRequest(this.full_api_url_id_query, requestBody).map(response => {
      if (response && response['data']) {
        let jobs: Job[] = [];

        for (let resJob of response['data']) {
          jobs.push(this.buildJob(resJob));
        }

        return jobs;
      }
    });
  }

  saveJob(job: Job): Observable<Job> {
    let requestUrl = this.full_api_url;
    let requestBody = this.buildRequestBody(job);
    let request;

    if (job.id) {
      request = this.dataService.putRequest(this.full_api_url + "/" + job.id, requestBody);
    }
    else {
      request = this.dataService.postRequest(this.full_api_url, requestBody);
    }


    return request.map(response => {
      if (response) {
        return this.buildJob(response);
      }
      else {
        null;
      }
    });
  }

  discardJob(job: Job): Observable<Job> {
    return this.dataService.deleteRequest(this.full_api_url + "/" + job.id).map(response => {
      return this.buildJob(response);
    });
  }

  archiveJob(job: Job): Observable<Job> {
    let requestBody = {
      'target': 'job',
      'action': 'archive'
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + job.id, requestBody).map(response => {
      return this.buildJob(response);
    });
  }

  dupliateJob(job: Job): Observable<Job> {
    let requestBody = {
      'job_id': job.id
    };

    return this.dataService.postRequest(this.full_api_url_duplicate, requestBody).map(response => {
      return this.buildJob(response);
    });
  }

  getDuplicateJobIds(job: Job): Observable<any[]> {
    return this.dataService.getRequest(this.full_api_url_duplicate + '/' + job.id).map(response => {
      let values = [];

      if (response && response['data']) {
        for (let item of response['data']) {
          values.push({
            'job': item['job'],
            'ts_created': new Date(item['ts_created']),
            'id_custom': item['id_custom']
          });
        }
      }

      return values;
    });
  }

  buildRequestBody(job: Job) {
    let requestBody = {};

    //simple parameters
    requestBody['id'] = job.id;
    requestBody['car_license_plate'] = job.car_license_plate;
    requestBody['car_manufacturer'] = job.car_manufacturer.id;
    requestBody['car_model'] = job.car_model.id;
    requestBody['car_model_text'] = job.car_model_text;
    requestBody['car_year'] = job.car_year;
    requestBody['car_chassis_number'] = job.car_chassis_number;
    requestBody['car_country'] = job.car_country.id;
    requestBody['id_custom'] = job.id_custom;
    requestBody['customer_id'] = job.customer_id;
    requestBody['customer_name'] = job.customer_name;
    requestBody['customer_phone'] = job.customer_phone;
    requestBody['bodyshop'] = job.bodyshop.id;
    requestBody['comments'] = job.comments;
    requestBody['benchmark_amount'] = job.benchmark_amount;


    //colours
    if (!job.id) {
      requestBody['colours'] = [];
      for (let colour of job.colours) {
        let colour_body = this.colourMatchService.buildRequestBody(colour)
        colour_body['colour_index'] = job.colours.indexOf(colour);
        requestBody['colours'].push(colour_body);
      }
    }

    //panels
    if (job.panels) {
      requestBody['panels'] = [];

      for (let panel of job.panels) {
        if (!panel.isSelected())
          continue;

        let panelParam = {
          'panel': panel.panel.id,
          'substrate': panel.substrate ? panel.substrate.id : null,
          'surfaces': []
        };

        if (job.id) {
          panelParam['colour'] = panel.colour ? panel.colour.id : null;
        }
        else {
          panelParam['colour_index'] = job.colours.indexOf(panel.colour);
        }

        for (let surface of panel.surfaces) {
          if (surface.is_selected) {
            let surfaceParam = {
              'is_selected': surface.is_selected,
              'is_inside': surface.is_inside,
              'repair': surface.repair ? surface.repair.id : null,
              'coats': []
            }

            for (let coat of surface.coats) {
              surfaceParam['coats'].push({
                'coat': coat.coat.id,
                'is_selected': coat.is_selected,
                'application_technique': coat.application_technique ? coat.application_technique.id : this.coatService.default_applicaitonTechnique.id
              });
            }

            panelParam['surfaces'].push(surfaceParam);
          }
        }

        requestBody['panels'].push(panelParam);
      }
    }

    //custom consumption
    if (job.customProductConsumption) {
      requestBody['custom_productConsumption'] = [];

      for (let item of job.customProductConsumption) {

        if (!item.quantity)
          continue;

        requestBody['custom_productConsumption'].push({
          'product': item.product.id,
          'quantity': item.quantity
        });
      }
    }

    return requestBody;
  }

  createEmptyJob() {
    let job = new Job();
    job.panels = [];

    if(!this.userService.currentUser){
      let sub = this.userService.initialised.subscribe(state => {
        if(state){
          job.bodyshop = this.userService.currentUser.currentBodyshop;
          sub.unsubscribe();
        }
      })
    }
    else{
      job.bodyshop = this.userService.currentUser.currentBodyshop;
    }
    

    for (let panel of this.panelService.panels) {
      let jobPanel = new JobPanel();
      jobPanel.panel = panel;
      jobPanel.substrate = panel.default_substrate;
      job.panels.push(jobPanel);
    }

    return job;
  }

  buildVariantWithCaching(response, variant_cache) {
    if (response['id']) {
      if (!variant_cache[response['id'].toString()]) {
        variant_cache[response['id'].toString()] = this.variantService.buildVariant(response);
      }

      return variant_cache[response['id'].toString()]
    }
    else {
      return undefined;
    }
  }

  buildJob(response) {
    let job = this.createEmptyJob();
    let variant_cache = {};

    for (let param of this.parameters_simple) {
      if (response[param]) {
        job[param] = response[param];
      }
    }

    job.user_id_created = response['user_created'];
    job.user_id_created = response['user_modified'];

    if (response['duplicated_from']) {
      job.duplicate_parent = {
        'job': response['duplicated_from']['job'],
        'ts_created': new Date(response['duplicated_from']['ts_created']),
        'id_custom': response['duplicated_from']['id_custom']
      };
    }

    if (response['ts_closed']) {
      job.date_closed = new Date(response['ts_closed']);
    }

    if (response['ts_discarded']) {
      job.date_discarded = new Date(response['ts_discarded']);
    }

    if (response['ts_created']) {
      job.date_created = new Date(response['ts_created']);
    }

    if (response['ts_modified']) {
      job.date_modified = new Date(response['ts_modified']);
    }

    if (response['cost']) {
      job.cost = response['cost'];
    }

    if (response['quantity_kg']) {
      job.quantity_kg = response['quantity_kg'];
    }

    if (response["car_manufacturer"])
      for (let cman of this.vehicleService.carManufacturers) {
        if (cman.id == response["car_manufacturer"]) {
          job.car_manufacturer = cman;
          break;
        }
      }

    if (response['car_model']) {
      let model_response = response['car_model'];
      let cman = this.vehicleService.getCarManufacturerById(model_response['car_manufacturer']);

      //check if it has been
      let model = undefined;
      if (cman) {
        for (let search_model of cman.models) {
          if (search_model.id == model_response['id'] && search_model.consumption && !search_model.estimates.isEmpty()) {
            model = search_model;
            break;
          }
        }
      }


      if (!model) {
        model = this.vehicleService.buildModel(model_response);
      }

      job.car_model = model;
    }


    if (response["bodyshop"]) {
      for (let bodyshop of this.userService.currentUser.bodyshops) {
        if (bodyshop.id == response["bodyshop"]) {
          job.bodyshop = bodyshop;
          break;
        }
      }
    }

    if (response["car_country"]) {
      job.car_country = this.countryService.getCountryById(response["car_country"]);
    }


    if (response["batches"]) {
      job.batches = []
      for (let batch of response["batches"]) {
        job.batches.push(this.batchService.buildBatch(batch));
      }

      job.sortBatches();
    }

    //colours
    if (response['colours']) {
      job.colours = [];
      for (let cMatch of response['colours']) {
        let jColour = this.colourMatchService.buildColourMatch(cMatch);

        for (let series of jColour.matchSeries) {
          if (series.batch_id) {
            series.batch = job.getBatchById(series.batch_id);
          }

          for (let attempt of series.attempts) {
            attempt.batch = job.getBatchById(attempt.batch_id);
          }
        }

        //set car manufacturer
        if (cMatch['colour'] && cMatch['colour']['car_manufacturer'] == job.car_manufacturer.id)
          jColour.colour.carManufacturer = job.car_manufacturer;

        job.colours.push(jColour);
      }
    }

    for (let cmatch of job.colours) {
      cmatch.relinkBatches(job.batches);
    }


    if (response["panels"]) {
      for (let res_panel of response["panels"]) {
        let panel = new JobPanel();

        //find panel
        for (let pnl of job.panels) {
          if (pnl.panel.id == res_panel['panel']) {
            panel = pnl;
          }
        }

        panel.colour = job.getColourMatchById(res_panel['colour']);

        if (res_panel['substrate']) {
          panel.substrate = this.substrateService.getSubstrateOffline(res_panel['substrate']);
        }

        if (res_panel['surfaces'] && res_panel['surfaces'].length > 0) {
          panel.surfaces = [];

          for (let surface of res_panel['surfaces']) {
            let newSurface = new JobPanelSurface();

            newSurface.is_inside = surface['is_inside'];
            newSurface.is_selected = surface['is_selected'];

            if (surface['repair']) {
              newSurface.repair = this.panelService.getRepairTypeById(surface['repair']);
            }

            if (surface['coats'] && surface['coats'].length > 0) {
              newSurface.coats = [];

              for (let coat of surface['coats']) {
                let newCoat = new JobPanelCoat();

                newCoat.coat = this.coatService.getCoatById(coat['coat']);
                newCoat.is_selected = coat['is_selected'];
                newCoat.application_technique = this.coatService.getApplicationTechniqueById(coat['application_technique']);

                if (!newCoat.application_technique) {
                  newCoat.application_technique = this.coatService.default_applicaitonTechnique;
                }

                newSurface.coats.push(newCoat);
              }
            }

            panel.surfaces.push(newSurface);
          }
        }

        //job.panels.push(panel);
      }
    }

    if (response['custom_productConsumption']) {
      job.customProductConsumption = [];

      for (let item of response['custom_productConsumption']) {
        let product = new JobCustomProduct();
        product.product = this.productService.getProductById(item['product']);
        product.quantity = item['quantity'];
        product.unit_cost = item['unit_cost'];

        job.customProductConsumption.push(product);
      }
    }

    return job;
  }

}
