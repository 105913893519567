<div id="container">
  <div id="top-wrapper">
    <div id="top">
      <div id="logo">
        <a [routerLink]="['/']">
          <img src="/assets/kansai_global_logo.svg">
        </a>
      </div>
      <div class="h-spacer"></div>
      <div id="navigation">
        <a class="page-link" href="#features" *ngIf="route.children.length == 0">Features</a>
        <a class="page-link" href="#produts" *ngIf="route.children.length == 0">Products</a>
        <a class="page-link" href="#contac" *ngIf="route.children.length == 0">Contact</a>
        <button mat-flat-button color="primary" (click)="launchApp()" [disabled]="is_launching">
          <ng-container *ngIf="is_launching">
            Launching...
          </ng-container>
          <ng-container *ngIf="!is_launching">
            Launch App
          </ng-container>
        </button>
      </div>
    </div>
  </div>
  <div id="body-wrapper" *ngIf="false">

    <ng-container *ngIf="route.children.length > 0">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-container *ngIf="route.children.length == 0">
      <div class="section-wrapper intro-section-wrapper">
        <div class="section intro-section">
          <div class="ir-left">
            <img class="img-demo-laptop" src="/assets/laptop_demo.png" alt="">
          </div>
          <div class="ir-right">
            <div class="intro-name in-jp product-font">
              精度
            </div>
            <div class="intro-name in-en product-font">
              PRECISION
            </div>
          </div>
        </div>
      </div>



      <div class="section-wrapper">
        <div class="section">
          <div class="section-header">
            <a name="features"></a>
            <div class="section-title">Features</div>
            <div class="h-spacer"></div>
          </div>
          Kansai Paint's <span class="txt-italic product-font">Precision</span> gives you full visibility of your bodyshop's
          operation.
          Whether it is finding the best colour match, control your inventory or keep a keen eye on your cost, <span
            class="txt-italic">Precision</span> puts you in the driver's seat!

        </div>
      </div>

      <div class="section-wrapper">
        <div class="section">
          <div class="section-header">
            <a name="products"></a>
            <div class="section-title">Products</div>
            <div class="h-spacer"></div>
          </div>
          You find out top global brands incorporated into <span class="txt-italic product-font">Precision</span>. Including our
          solvent-borne products as well as our new and innovative water-borne range.


          <div class="product-container">
            <div class="products">
              <div class="product">
                <div>
                  <img class="product-type-image" [src]="'/assets/product_types/wb-ev.png'">
                </div>
              </div>
              <div class="product">
                <div>
                  <img class="product-type-image" [src]="'/assets/product_types/pghbhs.png'">
                </div>
              </div>
              <div class="product">
                <div>
                  <img class="product-type-image" [src]="'/assets/product_types/cardea.png'">
                </div>
              </div>
              <div class="product">
                <div>
                  <img class="product-type-image" [src]="'/assets/product_types/pg80.png'">
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      <div class="section-wrapper">
        <div class="section">
          <div class="section-header">
            <a name="contact"></a>
            <div class="section-title">Contact</div>
            <div class="h-spacer"></div>
          </div>
          Find your nearest Kansai Paint representative: <a class="bottom-link" href="https://www.kansai.com/our-global-network/" target="blank">Global Network</a>

        </div>
      </div>
    </ng-container>



    <div class="section-wrapper foot-section-wrapper">
      <div class="section">
        <div class="h-section">
          <div class="block">
            <a class="bottom-link" href="http://www.kansaipaint.ae/" target="blank">About Us</a>
            <a class="bottom-link" [routerLink]="['/privacy']">Privacy Statement</a>
            <a class="bottom-link" [routerLink]="['/terms']">Terms &amp; Conditions</a>
          </div>


          <div class="block">
            <a class="bottom-link" href="https://www.kansai.com/ar_global/products/ev.html" target="blank">Waterborne
              Evolution</a>
            <a class="bottom-link" href="https://www.kansai.com/ar_global/products/hybrid.html" target="blank">PG
              Hybrid</a>
            <a class="bottom-link" href="http://www.cardeaglobal.com/" target="blank">Cardea</a>
            <a class="bottom-link" href="https://www.kansai.com" target="blank">Global Website</a>
          </div>

          <div class="block">
            <table class="contact-table">
              <tr>
                <td class="ct-cell entity-name" colspan="2">Kansai Paint Middle East FZCO</td>
              </tr>
              <tr>
                <td class="ct-cell" colspan="2">
                  Office 1402 <br>
                  Marina Plaza <br>
                  Dubai <br>
                  United Arab Emirates
                </td>
              </tr>
              <tr>
                <td class="ct-cell">Tel:</td>
                <td class="ct-cell">+971 4 388 2221</td>
              </tr>
              <tr>
                <td class="ct-cell">eMail:</td>
                <td class="ct-cell">
                  <a href="mailto:info@kansaipaint.ae">info@kansaipaint.ae</a>
                </td>
              </tr>
              <tr>
                <td class="ct-cell">Web:</td>
                <td class="ct-cell">
                  <a href="http://www.kansaipaint.ae">kansaipaint.ae</a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="copyright">
          &copy; {{getCurrentYear()}} Kansai Paint Middle East FZCO. All Rights Reserved.
        </div>
      </div>
    </div>


  </div>
</div>