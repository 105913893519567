import { LegalTerms, LegalTermsAcceptance, KansaiCompany } from './../../../model/model';
import { BodyshopService } from './bodyshop.service';
import { CountryService } from './country.service';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { User, Bodyshop, Tenant, Currency, Country } from '../../../model/model';
import { CurrencyService } from './currency.service';

@Injectable()
export class UserService {
  private api_endpoint = '/user';
  private full_api_url;


  private api_endpoint_users = '/users';
  private full_api_url_users;

  public currentUser: User;

  public initialised = new BehaviorSubject(false);
  public lastRefresh = null;


  constructor(public dataService: DataService,
    public countryService: CountryService,
    public currencyService:CurrencyService,
    private bodyshopService: BodyshopService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
    this.full_api_url_users = dataService.getUrl() + this.api_endpoint_users;
  }

  public refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.full_api_url).subscribe(response => {
      if (response) {
        this.currentUser = this.buildFullUser(response);

        this.lastRefresh = new Date();
        this.initialised.next(true);
      }

    });
  }

  getUsersForTenant(tenant: Tenant): Observable<User[]> {
    return this.dataService.getRequest(this.full_api_url_users + "/tenant/" + tenant.id).map(response => {
      let users: User[] = [];

      if (response && response['data']) {
        for (let res of response['data']) {
          let user = this.buildUser(res);
          if (user) {
            users.push(user);
          }
        }
      }

      return users;
    });
  }

  getUsersForBodyshop(bshop: Bodyshop): Observable<User[]> {
    return this.dataService.getRequest(this.full_api_url_users + "/bodyshop/" + bshop.id).map(response => {
      let users: User[] = [];

      if (response && response['data']) {
        for (let res of response['data']) {
          let user = this.buildUser(res);
          if (user) {
            users.push(user);
          }
        }
      }

      return users;
    });
  }

  getSingleUser(user_id: number): Observable<User> {
    return this.dataService.getRequest(this.full_api_url_users + "/" + user_id).map(response => {
      if (response && response['data']) {
        return this.buildUser(response['data'][0]);
      }
    });
  }

  acceptLicenseTerms(terms: LegalTerms[]): Observable<User> {
    let requestUrl = this.full_api_url;
    let requestBody = {
      'action': 'accept_terms',
      'params': {
        'terms': []
      }
    };

    for (let term of terms) {
      requestBody['params']['terms'].push(term.id);
    }

    return this.dataService.patchRequest(requestUrl, requestBody).map(response => {
      return this.buildFullUser(response);
    });
  }

  buildUser(response): User {
    let user: User;

    if (response) {
      user = new User();

      user.id = response['id'];
      user.email = response['email'];
      user.employee_nb = response['employee_nb'];
      user.name = response['full_name'];
      user.bodyshop_id = response['bodyshop'];
      user.is_active = response['is_active'];
      user.is_kansai = response['is_kansai'];
      user.is_developer = response['is_developer'];
      user.is_tenantAdmin = response['is_tenantAdmin'];
      user.is_allowed_preprod = response['is_allowed_preprod'];
      user.permissions = response['permissions'];
      user.tenant_is_viewCost = user.permissions['tenant']['is_viewCost'];
      user.tenant_level = user.permissions['tenant']['level'];

      if(response['legal_term_acceptances']){
        user.acceptedTerms = [];

        for(let resTerm of response['legal_term_acceptances']){
          let term = new LegalTermsAcceptance();
          term.terms_id = resTerm['legal_terms'];
          term.timestamp = new Date(resTerm['timestamp']);

          user.acceptedTerms.push(term);
        }
      }
    }

    return user;
  }

  private buildFullUser(response) {
    let user = this.buildUser(response);
    //console.log(response);

    user.bodyshops = [];

    //tenant    
    user.tenant = new Tenant();
    user.tenant.id = response['tenant']['id'];
    user.tenant.name = response['tenant']['name'];
    user.tenant.is_kansai = response['tenant']['is_kansai'];

    
    user.tenant.kansaiCompany = new KansaiCompany();
    user.tenant.kansaiCompany.id = response['tenant']['kansai_company']['id'];
    user.tenant.kansaiCompany.name = response['tenant']['kansai_company']['name'];

    user.tenant.currency = this.currencyService.buildCurrency(response['tenant']['currency']);
    user.tenant.country = this.countryService.buildCountry(response['tenant']['country']);

    //set current bodyshop if specified
    let bshop = this.bodyshopService.buildBodyshop(response['bodyshop']);

    user.currentBodyshop = bshop;

    return user;
  }



  private findBodyshopById(id: number) {
    let returnBs: Bodyshop = undefined;

    for (let bs of this.currentUser.bodyshops) {
      if (bs.id == id) {
        returnBs = bs;
        break;
      }
    }

    return returnBs;
  }


  bodyshopChange(newBodyshop) {
    this.currentUser.currentBodyshop = newBodyshop;
  }
}
