import { Bodyshop } from '../../../model/model';
import { Observable } from 'rxjs/Observable';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { User } from '../../../model/model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUserService {

  private api_endpoint = '/manage/user';
  private full_api_url;

  constructor(
    private dataService: DataService,
    private userService: UserService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getUser(id: number): Observable<User> {
    return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
      return this.buildUser(response);
    });
  }

  getUsers(bodyshop_id?: number): Observable<User[]> {
    let url = this.full_api_url;

    if (bodyshop_id) {
      url += "/bodyshop/" + bodyshop_id
    }

    return this.dataService.getRequest(url).map(response => {
      if (response && response['data']) {
        let users = [];

        for (let item of response['data']) {
          let user = this.buildUser(item);
          if (user)
            users.push(user);
        }

        return users;
      }
    });
  }

  saveUser(user: User): Observable<User> {
    let request;
    let requestBody = this.buildRequestBody(user);

    if (user.id) {
      request = this.dataService.putRequest(this.full_api_url + "/" + user.id, requestBody);
    }
    else {
      request = this.dataService.postRequest(this.full_api_url, requestBody);
    }

    return request.map(response => {
      return this.buildUser(response);
    });
  }

  resetPassword(user: User): Observable<boolean> {
    let requestBody = {
      'target': 'user',
      'action': 'password_reset',
      'params': {
        'user': user.id
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + user.id, requestBody).map(response => {
      if (response) {
        return true;
      }
      else {
        return false;
      }
    });
  }

  disableUser(user: User): Observable<User> {
    let requestBody = {
      'target': 'user',
      'action': 'disable',
      'params': {
        'user': user.id
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + user.id, requestBody).map(response => {
      if (response) {
        return this.buildUser(response);
      }
      else {
        return null;
      }
    });
  }

  enableUser(user: User): Observable<User> {
    let requestBody = {
      'target': 'user',
      'action': 'enable',
      'params': {
        'user': user.id
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + user.id, requestBody).map(response => {
      if (response) {
        return this.buildUser(response);
      }
      else {
        return null;
      }
    });
  }

  deleteUser(user: User): Observable<boolean> {
    return this.dataService.deleteRequest(this.full_api_url + "/" + user.id).map(response => {
      if (response) {
        return true
      }
      else {
        return false;
      }
    });
  }

  buildRequestBody(user: User) {
    let requestBody = {
      'full_name': user.name,
      'email': user.email,
      'employee_nb': user.employee_nb,
      'bodyshop': user.currentBodyshop ? user.currentBodyshop.id : user.bodyshop_id,
      'permissions': {
        'tenant': {
          'level': user.tenant_level,
          'is_viewCost': user.tenant_is_viewCost
        }
      },
    };

    return requestBody;
  }


  buildUser(response): User {
    return this.userService.buildUser(response);
  }
}
