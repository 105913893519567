import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Substrate } from '../../../model/model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubstrateService {

  private api_endpoint = '/substrate';
  private full_api_url;

  public substrates: Substrate[] = [];
  public initialised = new BehaviorSubject(false); //for caching substrates

  constructor(public dataService:DataService) { 
    this.full_api_url = this.dataService.getUrl() + this.api_endpoint;
  } 

  getSubstrate(id: string): Observable<Substrate> {
    for (let cachedSubstrate of this.substrates) {
      if (cachedSubstrate.id == id) {
        return new Observable((observer) => {
          observer.next(cachedSubstrate);
          observer.complete();
        });
      }
    }

    //if not found in cache, get from server
    let requestUrl = this.full_api_url;
    requestUrl += "/" + id;

    this.dataService.getRequest(requestUrl).map(response => {
      return this.buildSubstrate(response);
    });
  }

  getSubstrateOffline(id: string): Substrate {
    for (let cachedSubstrate of this.substrates) {
      if (cachedSubstrate.id == id) {
        return cachedSubstrate;
      }
    }
  }

  buildSubstrate(response): Substrate {
    let substrate = new Substrate();

    substrate.id = response['id'];
    substrate.name = response['name'];

    return substrate;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    this.dataService.getRequest(this.full_api_url).subscribe(response => {
      if (response && response['data']) {
        this.substrates = [];

        for (let substrate of response['data']) {
          this.substrates.push(this.buildSubstrate(substrate));
        }

        this.initialised.next(true);
      }
    });
  }
}
