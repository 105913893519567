import { SubstrateService } from './substrate.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { Toolkit } from '../../../toolkit/toolkit';
import { CoatService } from './coat.service';
import { ProductService } from './product.service';
import { VariantService } from './variant.service';
import { Job, Batch, BatchComponent, Coat, Variant, Product, FormulaComponent, BatchOvershot, VariantSystem, User } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable, Input } from '@angular/core';

@Injectable()
export class BatchService {
  private api_endpoint = '/batch';
  private full_api_url;

  constructor(public dataService: DataService,
    private variantService: VariantService,
    private productService: ProductService,
    public userService: UserService,
    public substrateService: SubstrateService,
    private coatService: CoatService) {

    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  static WORKONQUEUE(queuer) {
    if (queuer['queue'] && queuer['queue'].length > 0) {
      let request = queuer['queue'][0];
      request['request'].subscribe(response => {
        let batch: Batch = request['batch'];

        batch.ts_created = response['ts_created'];
        
        batch.user_id_created = response['user_created'];
        batch.user_id_modified = response['user_modified'];
        batch.user_id_assigned = response['user_assigned'];

        //update latest cost_per_kg
        if (response['components']) {
          for (let component of response['components']) {
            let foundComponent = batch.getComponentByProductId(component['product'])
            if (foundComponent) {
              foundComponent.cost_per_kg = +component['cost_per_kg'];
            }
          }
        }

        if (response['components_ancillary']) {
          for (let component of response['components_ancillary']) {
            let foundComponent = batch.getComponentByProductId(component['product']);
            if (foundComponent) {
              foundComponent.cost_per_kg = +component['cost_per_kg'];
            }
          }
        }

        queuer['queue'].splice(0, 1);
        setTimeout(() => { BatchService.WORKONQUEUE(queuer) }, 1);
      });
    }
    else if (!queuer['killSwitch']) {
      {
        setTimeout(() => { BatchService.WORKONQUEUE(queuer) }, 500);
      }
    }
  }

  getUploadQueuer() {
    let queuer = {
      'queue': [],
      'killSwitch': false
    };

    setTimeout(() => {
      BatchService.WORKONQUEUE(queuer)
    }, 500);

    return queuer;
  }

  killUploadQueuer(queuer) {
    queuer['killSwitch'] = true;
  }

  getBatch(id: number) {
    if (id) {
      return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
        if (response) {
          return this.buildBatch(response);
        }
      });
    }
  }

  getBatchesForJob(job: Job) {
    return this.dataService.getRequest(this.full_api_url + "/job/" + job.id).map(response => {
      if (response && response['data']) {
        let batches = [];

        for (let batch of response['data']) {
          batches.push(this.buildBatch(batch));
        }

        return batches;
      }
    });
  }

  buildRequestBody(batch: Batch) {
    let requestBody = {};

    if (batch) {
      if (batch.id)
        requestBody['id'] = batch.id;

      //formula
      requestBody['formula'] = [];
      for (let component of batch.formula) {
        requestBody['formula'].push({
          'ratio': Toolkit.precision(component.ratio, 4),
          'error': Toolkit.precision(component.error, 4),
          'product': component.product ? component.product.id : null,
          'product_category': component.product_category ? component.product_category.id : null
        });
      }

      requestBody['formula_ancillary'] = [];
      for (let component of batch.formula_ancillary) {
        requestBody['formula_ancillary'].push({
          'ratio': Toolkit.precision(component.ratio, 4),
          'error': Toolkit.precision(component.error, 4),
          'product': component.product ? component.product.id : null,
          'product_category': component.product_category ? component.product_category.id : null
        });
      }

      //components
      requestBody['components'] = [];
      for (let component of batch.components) {
        requestBody['components'].push({
          'product': component.product ? component.product.id : null,
          'quantity_kg': component.quantity_kg
        });
      }

      requestBody['components_ancillary'] = [];
      for (let component of batch.components_ancillary) {
        if (component.product) {
          requestBody['components_ancillary'].push({
            'product': component.product ? component.product.id : null,
            'quantity_kg': component.quantity_kg
          });
        }
      }


      //recalculations
      requestBody['recalculations'] = []
      for (let overshot of batch.recalculations) {
        requestBody['recalculations'].push({
          'product': overshot.product.id,
          'target_kg': overshot.target_kg,
          'actual_kg': overshot.actual_kg,
          'timestamp': overshot.timestamp.toISOString()
        });
      }

      //panel selections
      if (batch.panelSelection && batch.panelSelection['selection']) {
        requestBody['panel_selection'] = []

        for (let panel in batch.panelSelection['selection']) {
          requestBody['panel_selection'].push({
            'appTech': batch.panelSelection['selection'][panel]['appTech']['id'],
            'panel': batch.panelSelection['selection'][panel]['panel']['panel']['id'],
            'targets': batch.panelSelection['selection'][panel]['targets'],
            'substrate': batch.panelSelection['selection'][panel]['substrate']['id']
          })
        }
      }

      if (batch.system) {
        requestBody['system'] = batch.system.toJson();
      }


      //generic fields
      requestBody['quantity_target_kg'] = batch.quantity_target_kg;
      requestBody['coat'] = batch.coat.id;
      requestBody['product_type'] = batch.product_type.id;
      requestBody['is_discarded'] = batch.is_discarded;
      requestBody['is_locked'] = batch.is_locked;
      requestBody['is_matchingBatch'] = batch.is_matchingBatch;
      requestBody['is_fromPreMix'] = batch.is_fromPreMix;
      requestBody['base_product'] = batch.baseProduct ? batch.baseProduct.id : null;
      requestBody['substrate'] = batch.substrate ? batch.substrate.id : null;
      requestBody['application_technique'] = batch.applicationTechnique ? batch.applicationTechnique.id : null;
      requestBody['variant'] = batch.variant_id ? batch.variant_id : null;
      requestBody['job'] = batch.job_id ? batch.job_id : null;
      requestBody['colour'] = batch.colour_id ? batch.colour_id : null;
      requestBody['colourMatch'] = batch.colourMatch_id ? batch.colourMatch_id : null;
      requestBody['bodyshop'] = this.userService.currentUser.currentBodyshop.id;
    }

    return requestBody;
  }

  deleteBatch(batch: Batch, queuer?): Observable<Batch> {
    return this.dataService.deleteRequest(this.full_api_url + "/" + batch.id).map(response => {
      if (response) {
        return this.buildBatch(response);
      }
    });
  }


  closeBatch(batch:Batch): Observable<Batch>{
    let requestUrl = this.full_api_url + "/" + batch.id;
    let requestBody = {
      'target': 'batch',
      'action': 'close'
    }

    return this.dataService.patchRequest(requestUrl, requestBody).map(response => {
      return this.buildBatch(response);
    })
  }

  createBatch(batch: Batch): Observable<Batch> {
    let requestBody = this.buildRequestBody(batch);
    let request = this.dataService.postRequest(this.full_api_url, requestBody);

    return request.map(response => {
      if (response) {
        let newBatch = this.buildBatch(response);
        batch.ts_created = newBatch.ts_created;
        batch.user_id_created = newBatch.user_id_created;
        batch.user_id_modified = newBatch.user_id_modified;
        batch.user_id_assigned = newBatch.user_id_assigned;

        return newBatch;
      }
    });
  }

  updateBatch(batch: Batch, queuer) {
    let requestBody = this.buildRequestBody(batch);
    let request = this.dataService.putRequest(this.full_api_url + "/" + batch.id, requestBody);

    queuer['queue'].push({ 'request': request, 'batch': batch });
  }


  splitBatch(batch: Batch, split_kg: number): Observable<Batch[]> {
    let requestUrl = this.full_api_url + "/" + batch.id;
    let requestBody = {
      'target': 'batch',
      'action': 'split',
      'split_kg': split_kg
    };

    return this.dataService.patchRequest(requestUrl, requestBody).map(response => {
      let batches: Batch[] = [];

      if (response) {
        let originalBatch = this.buildBatch(response['source']);
        let newBatch = this.buildBatch(response['target']);

        batches.push(originalBatch);
        batches.push(newBatch);
      }

      return batches;
    });
  }

  assignBatch(batch:Batch, user_assign_to:User): Observable<Batch>{
    let requestUrl = this.full_api_url + "/" + batch.id;
    let requestBody = {
      'target': 'batch',
      'action': 'assign',
      'user_assign_to': user_assign_to.id
    };

    return this.dataService.patchRequest(requestUrl, requestBody).map(response => {
      return this.buildBatch(response);
    });
  }

  buildBatch(response) {
    let batch: Batch = new Batch();

    if (response) {
      batch.id = response['id'];

      batch.quantity_target_kg = response['quantity_target_kg'];
      batch.is_locked = response['is_locked'];
      batch.coat = this.coatService.getCoatById(response['coat']);
      batch.product_type = this.productService.getProductTypeById(response['product_type']);
      batch.is_discarded = response['is_discarded'];
      batch.is_matchingBatch = response['is_matchingBatch'];
      batch.is_fromPreMix = response['is_fromPreMix'];
      batch.baseProduct = response['base_product'] ? this.productService.getProductById(response['base_product']) : undefined;
      batch.substrate = response['substrate'] ? this.substrateService.getSubstrateOffline(response['substrate']) : undefined;
      batch.applicationTechnique = response['application_technique'] ? this.coatService.getApplicationTechniqueById(response['application_technique']) : undefined;
      batch.variant_id = response['variant'] ? response['variant'] : undefined;
      batch.job_id = response['job'] ? response['job'] : undefined;
      batch.colour_id = response['colour'] ? response['colour'] : undefined;
      batch.colourMatch_id = response['colourMatch'] ? response['colourMatch'] : undefined;
      batch.ts_created = new Date(response['ts_created']);
      batch.user_id_assigned = response['user_assigned'];
      batch.user_id_created = response['user_created'];
      batch.user_id_modified = response['user_modified'];

      /*
      if(batch.user_id_assigned){
        this.userService.getSingleUser(batch.user_id_assigned).subscribe(user => {
          batch.user_assigned = user;
        })
      }

      if(batch.user_id_created){
        this.userService.getSingleUser(batch.user_id_created).subscribe(user => {
          batch.user_created = user;
        })
      }
      */

      //system
      if (response['system']) {
        batch.system = this.variantService.buildSystem(response['system']);
      }

      //formula
      batch.formula = [];
      if (response['formula']) {
        for (let component of response['formula']) {
          let newComp = new FormulaComponent();
          newComp.product = component['product'] ? this.productService.getProductById(component['product']) : undefined;
          newComp.product_category = component['category'] ? this.productService.getProductCategoryById(component['category']) : undefined;
          newComp.ratio = Toolkit.precision(component['ratio'], 4);
          newComp.error = Toolkit.precision(component['error'], 4);

          batch.formula.push(newComp);
        }
      }

      batch.formula_ancillary = [];
      if (response['formula_ancillary']) {
        for (let component of response['formula_ancillary']) {
          let newComp = new FormulaComponent();
          newComp.product = component['product'] ? this.productService.getProductById(component['product']) : undefined;
          newComp.product_category = component['category'] ? this.productService.getProductCategoryById(component['category']) : undefined;
          newComp.ratio = Toolkit.precision(component['ratio'], 4);
          newComp.error = Toolkit.precision(component['error'], 4);

          batch.formula_ancillary.push(newComp);
        }
      }

      //components
      batch.components = [];
      if (response['components']) {
        for (let component of response['components']) {
          let newComp = new BatchComponent();
          newComp.product = component['product'] ? this.productService.getProductById(component['product']) : undefined;
          newComp.quantity_kg = component['quantity_kg'];
          newComp.tare = newComp.quantity_kg;
          newComp.cost_per_kg = component['cost_per_kg'];

          batch.components.push(newComp);
        }
      }


      batch.components_ancillary = [];
      if (response['components_ancillary']) {
        for (let component of response['components_ancillary']) {
          let newComp = new BatchComponent();
          newComp.product = component['product'] ? this.productService.getProductById(component['product']) : undefined;
          newComp.quantity_kg = component['quantity_kg'];
          newComp.tare = newComp.quantity_kg;
          newComp.cost_per_kg = component['cost_per_kg'];

          batch.components_ancillary.push(newComp);
        }
      }

      if (response['recalculations']) {
        batch.recalculations = [];
        for (let overshot of response['recalculations']) {
          let over = new BatchOvershot();
          over.product = this.productService.getProductById(overshot['product']);
          over.actual_kg = Toolkit.precision(overshot['actual_kg'], 5);
          over.target_kg = Toolkit.precision(overshot['target_kg'], 5);
          over.timestamp = new Date(overshot['timestamp']);

          batch.recalculations.push(over);
        }
      }
    }

    return batch;
  }
}