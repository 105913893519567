import { Injectable, EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UiBlockService {
  private lock_counter:number = 0;
  public onChange = new EventEmitter<boolean>();
  public messages = {};

  constructor() { }

  block(message?:string){
    let id = uuid();
    this.messages[id] = message;

    this.lock_counter += 1;
    this.fire();
    
    return id;
  }

  unblock(id?:string){
    if(id){
      delete this.messages[id];
    }

    this.lock_counter -= 1;

    if(this.lock_counter < 0){
      this.lock_counter = 0;
      this.messages = {};
    }
    this.fire();
  }

  private fire(){
    this.onChange.emit(this.lock_counter > 0);
  }

  getMessages(){
    let messages = [];

    for(let id in this.messages){
      messages.push(this.messages[id]);
    }

    return messages;
  }
}
