import { CountryService } from './country.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Substrate, Coat } from '../../../model/model';
import { CoatService } from './coat.service';
import { InventoryService } from './inventory.service';
import { ProductService } from './product.service';
import { UserService } from './user.service';
import { VehicleService } from './vehicle.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/distinctUntilChanged';
import { RepairType } from '../../../model/model';
import { PanelService } from './panel.service';
import { SubstrateService } from './substrate.service';
import { MixRatioService } from './mix-ratio.service';
import { SecurityRightService } from './security-right.service';

@Injectable()
export class CacheService {
  public services;
  public loadingPercentage;

  public repairTypes:RepairType[] = [];
  public substrates:Substrate[] = [];

  public default_repair = "block-repair";
  public default_substrate = "metal";

  public isFullyLoaded = new BehaviorSubject<boolean>(false);

  constructor(
    private vehicleService: VehicleService,
    private userService: UserService,
    private productService: ProductService,
    private inventoryService: InventoryService,
    private coatService:CoatService,
    private panelService:PanelService,
    private countryService:CountryService,
    private substrateService:SubstrateService,
    private mixRatioService:MixRatioService,
    private securityRightService:SecurityRightService
  ) {
    this.services = [
      vehicleService,
      coatService,
      userService,
      productService,
      inventoryService,
      substrateService,
      panelService,
      countryService,
      mixRatioService,
      securityRightService
    ]
  }


  refreshData() {
    this.loadingPercentage = 0;

    for (let service of this.services) {
      service.refreshData();      
      service.initialised.distinctUntilChanged().subscribe(response => {
        if (response) {
          this.loadingPercentage += 100 / this.services.length;

          if(this.loadingPercentage > 99.5){
            this.isFullyLoaded.next(true);
          }
        }
      });
    }
  }
}
