import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class DataService {
  private api_url = 'https://kansaiprecision.com/api';
  public blockUIforLoad = false;

  public debugInfo:any[] = [];
  public debugCountMax:number = 100;

  constructor(private http: HttpClient) { 
    if(environment.localhost){
      this.api_url = 'http://localhost:5000/api';
    }
    else{
      if(environment.production_db){
        this.api_url = 'https://kansaiprecision.com/api';
      }
      else{
        this.api_url = 'https://preprod.kansaiprecision.com/api';
      }
    }
  }

  getUrl() {
    return this.api_url;
  }

  getRequest(url: string) {
    if (!environment.production) {
      console.log((new Date()).toLocaleTimeString() + " GET " + url);
    }

    this.logDebug("GET", url);

    return this.http.get(url);
  }

  postRequest(url: string, requestBody: object) {
    if (!environment.production) {
      console.log((new Date()).toLocaleTimeString() + " POST " + url);
      console.log(requestBody);
    }

    this.logDebug("POST", url);

    return this.http.post(url, requestBody);
  }

  putRequest(url: string, requestBody: object) {
    if (!environment.production) {
      console.log((new Date()).toLocaleTimeString() + " PUT " + url);
      console.log(requestBody);
    }

    this.logDebug("PUT", url);

    return this.http.put(url, requestBody);
  }

  deleteRequest(url: string) {
    if (!environment.production) {
      console.log((new Date()).toLocaleTimeString() + " DELETE " + url);
    }

    this.logDebug("DELETE", url);

    return this.http.delete(url);
  }

  patchRequest(url: string, requestBody: object) {
    if (!environment.production) {
      console.log((new Date()).toLocaleTimeString() + " PATCH " + url);
      console.log(requestBody);
    }

    this.logDebug("PATCH", url);

    return this.http.patch(url, requestBody);
  }

  logDebug(method:string, url:string){
    let overRun = this.debugInfo.length + 1 - this.debugCountMax;

    if(overRun > 0){
      this.debugInfo.splice(0, overRun);
    }

    this.debugInfo.push({
      "url": url,
      "method": method,
      "timestamp": new Date()
    });
  }
}
