import { Toolkit } from '../../../toolkit/toolkit';
import { Bodyshop, Variant, VariantCoat, ProductCategory, ProductType, Product, InventoryConsumption, InventoryConsumptionItem } from '../../../model/model';
import { ProductService } from './product.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/distinctUntilChanged';
import { Observable } from 'rxjs/Observable';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { InventoryItem } from '../../../model/model';

@Injectable()
export class InventoryService {
  private api_endpoint = '/inventory';
  private full_api_url;


  private api_endpoint_consumption = '/inventory-consumption';
  private full_api_url_consumption;

  private map = {};

  //public inventory = [];
  public inventory: InventoryItem[] = [];
  public bodyshop: Bodyshop;
  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  private inventoryMap = {};

  constructor(public dataService: DataService,
    public userService: UserService,
    public productService: ProductService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
    this.full_api_url_consumption = dataService.getUrl() + this.api_endpoint_consumption;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    //wait until the user is initialised
    this.userService.initialised.distinctUntilChanged().subscribe(state => {
      this.productService.initialised.distinctUntilChanged().subscribe(productSatate => {
        if (state) {
          if (productSatate) {
            this.getInventory(this.userService.currentUser.currentBodyshop.id).subscribe(inv => {
              this.inventory = inv;

              this.lastRefresh = new Date();
              this.initialised.next(true);
            },
              error => {
                throw error;
              });
          }
        }
      });
    });
  }

  getInventory(bodyshopId: number): Observable<InventoryItem[]> {
    let requestUrl = this.full_api_url + "/bodyshop/" + bodyshopId;

    return this.dataService.getRequest(requestUrl).map(response => {
      let inv: InventoryItem[];

      if (response['data']) {
        inv = this.buildInventory(response['data']);
      }

      return inv;
    });
  }

  buildInventory(response): InventoryItem[] {
    let inv: InventoryItem[] = [];

    for (let entry of response) {
      let invItem = this.buildInventoryItem(entry);

      if (invItem) {
        inv.push(invItem);
        this.map["" + invItem.product.id] = invItem;
      }
    }

    return inv;
  }

  buildInventoryItem(response): InventoryItem {
    let invItem = new InventoryItem();
    invItem.id = response['id'];
    invItem.product = this.productService.getProductById(response['product']);
    invItem.type = response['type'];
    invItem.unit_cost = response['unit_cost'];
    invItem.active = true;
    invItem.stock_level_current = response['stock_level_current'];
    invItem.stock_level_alert = response['stock_level_alert'];
    invItem.is_consume = response['is_consume'];

    return invItem;
  }

  getByProductId(productId: string): InventoryItem {
    let ret_pt = this.map["" + productId];

    if (!ret_pt) { // only loop if not found to make sure manual edits are captured
      for (let item of this.inventory) {
        if (item.product && item.product.id == productId) {
          ret_pt = item;
        }
      }
    }

    return ret_pt;
  }

  getProductsByCategory(category: ProductCategory) {
    let result = []

    for (let item of this.inventory) {
      if (item.product && item.product.category.id == category.id) {
        result.push(item.product);
      }
    }

    return result;
  }

  updateInventory(bodyshopId: number, itemsUpdate: InventoryItem[], itemsAdd: InventoryItem[], itemsDelete: InventoryItem[]): Observable<InventoryItem[]> {
    let requestUrl = this.full_api_url + "/bodyshop/" + bodyshopId;
    let requestBody = {
      'action': 'configure'
    };

    //update
    if (itemsUpdate.length > 0) {
      requestBody['update'] = [];

      for (let item of itemsUpdate) {
        requestBody['update'].push({
          'product': item.product.id,
          'unit_cost': item.unit_cost,
          'stock_level_alert': item.stock_level_alert ? item.stock_level_alert : 0,
          'is_consume': item.is_consume ? true : false
        });
      }
    }

    //add
    if (itemsAdd.length > 0) {
      requestBody['add'] = [];

      for (let item of itemsAdd) {
        requestBody['add'].push({
          'product': item.product.id,
          'unit_cost': item.unit_cost,
          'stock_level_alert': item.stock_level_alert ? item.stock_level_alert : 0,
          'is_consume': item.is_consume ? true : false
        });
      }
    }

    //delete
    if (itemsDelete.length > 0) {
      requestBody['delete'] = [];

      for (let item of itemsDelete) {
        requestBody['delete'].push({
          'product': item.product.id
        });
      }
    }

    return this.dataService.patchRequest(requestUrl, requestBody).map(respone => {
      return this.buildInventory(respone['data']);
    });
  }

  updateValues(oldInventory: InventoryItem[], newInventory: InventoryItem[]) {
    //update existing items and add new items
    for (let newItem of newInventory) {
      let found = false;

      for (let oldItem of oldInventory) {
        if (newItem.product.id == oldItem.product.id) {
          found = true;
          oldItem.unit_cost = newItem.unit_cost;
          oldItem.stock_level_alert = newItem.stock_level_alert;
          oldItem.is_consume = newItem.is_consume;
          break;
        }
      }

      if (!found) {
        oldInventory.push(newItem);
      }
    }

    //remove items that were deleted
    for (let oldItem of oldInventory) {
      let found = false;

      for (let newItem of newInventory) {
        if (newItem.product.id == oldItem.product.id) {
          found = true;
          break;
        }
      }

      if (!found) {
        oldInventory.splice(oldInventory.indexOf(oldItem), 1);
      }
    }
  }

  adjustStockLevels(bodyshopId, itemsToUpdate: any[]): Observable<InventoryItem[]> {
    let requestUrl = this.full_api_url + "/bodyshop/" + bodyshopId;
    let requestBody = {
      'action': 'stock_level_adjust'
    };

    requestBody['adjustments'] = [];
    for (let item of itemsToUpdate) {
      let adjustmentItem = {
        'product': item['product'].id,
        'adjustment': item['adjustment']
      };

      requestBody['adjustments'].push(adjustmentItem);
    }

    return this.dataService.patchRequest(requestUrl, requestBody).map(respone => {
      return this.buildInventory(respone['data']);
    });
  }



  allVariantComponentsInInventory(variant: Variant) {
    let allAvailable = true;

    for (let coat of variant.coats) {
      if (!this.allFormulaComponentsInInventory(coat)) {
        allAvailable = false;
        break;
      }
    }

    return allAvailable;
  }

  allFormulaComponentsInInventory(coat: VariantCoat) {
    let allAvailable = true;

    for (let component of coat.formula) {
      if (this.getByProductId(component.product.id) == null) {
        allAvailable = false;
        break;
      }
    }


    return allAvailable;
  }

  costForFormula(coat: VariantCoat, kg: number) {
    let total = 0.0;
    //this.normalise(formula, kg);

    for (let component of coat.formula) {
      if (!this.getByProductId(component.product.id)) {
        return 0;
      }
      else {
        total +=
          this.getByProductId(component.product.id).unit_cost
          * component.product.kg_conversion
          * component.ratio;
      }
    }

    return Toolkit.precision(total * kg, 5);
  }

  costForVariant(variant: Variant, kg: number) {
    let total = 0;

    for (let coat of variant.coats) {
      total += this.costForFormula(coat, kg);
    }

    return total;
  }

  getAllTinters(): Product[] {
    let products = [];

    for (let item of this.inventory) {
      if (item.product.is_kansai && item.product.category.id == 'tinter') {
        products.push(item.product);
      }
    }

    return products;
  }

  getAllTintersForProductType(product_type: ProductType) {
    let products = [];

    for (let product of this.getAllTinters()) {
      if (product.type.id == product_type.id) {
        products.push(product);
      }
    }

    return products;
  }

  getAllNonTinters(): Product[] {
    let products = [];

    for (let item of this.inventory) {
      if (item.product.is_kansai && item.product.category.id != 'tinter') {
        products.push(item.product);
      }
    }

    return products;
  }

  getAllNonTintersForProductType(product_type: ProductType): Product[] {
    let products = [];

    for (let product of this.getAllNonTinters()) {
      if (product.type.id == product_type.id) {
        products.push(product);
      }
    }

    return products;
  }

  getAllKansaiProducts(): Product[] {
    let products = [];

    for (let item of this.inventory) {
      if (item.product.is_kansai) {
        products.push(item.product);
      }
    }

    return products;
  }

  getAllKansaiProductsForProductType(product_type: ProductType): Product[] {
    let products = [];

    for (let product of this.getAllKansaiProducts()) {
      if (product.type.id == product_type.id) {
        products.push(product);
      }
    }

    return products;
  }

  getInventoryConsumptionForBodshops(bodyshops: Bodyshop[], ts_from: Date, ts_to: Date): Observable<InventoryConsumption[]> {
    let requestUrl = this.full_api_url_consumption;
    let requestBody = {
      'bodyshops': [],
      'ts_from': ts_from.toISOString(),
      'ts_to': ts_to.toISOString()
    };

    for (let bodyshop of bodyshops) {
      requestBody['bodyshops'].push(bodyshop.id);
    }

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      let consumption = [];

      if (response && response['data']) {
        for (let resConsumption of response['data']) {
          let cons = this.buildConsumption(resConsumption);

          if (cons) {
            consumption.push(cons);
          }
        }
      }

      return consumption;
    });
  }

  buildConsumption(response): InventoryConsumption {
    let consumption: InventoryConsumption;

    if (response) {
      consumption = new InventoryConsumption();
      consumption.batch_id = response['batch'];
      consumption.bodyshop_id = response['bodyshop'];
      consumption.id = response['id'];
      consumption.tenant_id = response['tenant'];
      consumption.timestamp = new Date(response['timestamp']);
      consumption.type = response['type'];
      consumption.user_id = response['user'];
      consumption.items = [];

      for (let resItem of response['items']) {
        let item = this.buildConsumptionItem(resItem);
        if (item) {
          consumption.items.push(item);
        }
      }
    }

    return consumption;
  }

  buildConsumptionItem(response): InventoryConsumptionItem {
    let item: InventoryConsumptionItem;

    if (response) {
      item = new InventoryConsumptionItem();
      item.inventoryItem_id = response['inventoryItem'];
      item.quantity = response['quantity'] ? parseFloat(response['quantity']) : 0.0;
      item.quantity_kg = response['quantity_kg'] ? parseFloat(response['quantity_kg']) : 0.0;
      item.value = response['value'] ? parseFloat(response['value']) : 0.0;
      item.unit = this.productService.getUnitById(response['unit']);
      item.product = this.productService.getProductById(response['product']);
    }

    return item;
  }
}
