import { Observable } from 'rxjs/Observable';
import { LegalTerms } from './../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LegalTermsService {
  private api_endpoint = '/legal-terms';
  private full_api_url;

  constructor(private dataService: DataService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getLatestPrivacyPolicy(scope:string):Observable<LegalTerms>{
    return this.getLatestTermsByType(scope, 'privacy_policy');
  }

  getLatestTermsAndConditions(scope:string):Observable<LegalTerms>{
    return this.getLatestTermsByType(scope, 'terms_and_conditions');
  }

  getLatestTermsByType(scope:string, type:string):Observable<LegalTerms>{
    return this.dataService.getRequest(this.full_api_url + '/type/' + scope + '/' + type).map(result => {
      if(result){
        return this.buildTerms(result);
      }
    });
  }  

  getTermsById(id:number):Observable<LegalTerms>{
    return this.dataService.getRequest(this.full_api_url + '/' + id).map(result => {
      if(result){
        return this.buildTerms(result);
      }
    });
  }

  buildTerms(response):LegalTerms{
    let terms = new LegalTerms();

    terms.id = response['id'];
    terms.ts_created = response['ts_created'];
    terms.ts_valid_from = response['ts_valid_from'];
    terms.ts_modified = response['ts_modified'];
    terms.type = response['type'];
    terms.scope = response['scope'];
    terms.text_html = response['text_html'];

    return terms;
  }
}
