import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Currency } from '../../../model/model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private api_endpoint = '/currency';
  private full_api_url;

  constructor(private dataService: DataService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getCurrencyById(id: string): Observable<Currency> {
    return this.dataService.getRequest(this.full_api_url + '/' + id).map(response => {
      if (response) {
        let currency = this.buildCurrency(response);
        return currency;
      }
      else {
        return null;
      }
    });
  }

  getAllCurrencies(): Observable<Currency[]> {
    return this.dataService.getRequest(this.full_api_url).map(response => {
      if (response && response['data']) {
        let currencies: Currency[] = [];

        for (let item of response['data']) {
          let currency = this.buildCurrency(item);
          currencies.push(currency);
        }
        return currencies;
      }
      else {
        return null;
      }
    });
  }

  buildCurrency(response): Currency {
    let currency = new Currency();
    currency = new Currency();
    currency.id = response['id'];
    currency.iso_4217 = response['iso_4217'];
    currency.name = response['name'];

    return currency;
  }
}
