import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { Injector } from '@angular/core/';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private inj: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let auth = this.inj.get(AuthService);
    let token = auth.getToken();
    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        ApiKey: 'API VYs3W5mnfjqV!6JGfD4vZKhqWz!K&7BXK@fdxW!bMDd^Sx!BSQxnxdQRDU4fdUvMDHMz#NPvry4vkQmsEv*zB'
      }
    });

    return next.handle(request);
  }
}