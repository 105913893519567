import { AuthService } from '../../../shared/services/auth.service';
import { PasswordSetService } from '../../../shared/services/password-set.service';
import { ActivatedRoute, Router } from '@angular/router/';
import { Component, OnInit } from '@angular/core';
import { initTransferState } from '@angular/platform-browser/src/browser/transfer_state';
 
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  public authToken: string;
  public _isLocked: boolean = true;
  public _isInvalid: boolean = false;
  public _isChecking: boolean = true;
  public _isSaving:boolean = false;
  public _isInError:boolean = false;
  public _error:string;
  public _password: string;
  public _passwordRepeat: string;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private passwordSetService: PasswordSetService) {

      console.log(this);

    this.route.params.subscribe(params => {
      this.authToken = params['authKey'];

      this.passwordSetService.checkTokenValidity(this.authToken).subscribe(state => {
        this._isLocked = !state;
        this._isInvalid = !state;
        this._isChecking = false;
      },
        error => {
          this._isChecking = false;
          throw error;
        });
    });
  }

  ngOnInit() {
  }

  submit() {
    if (this.isValid()) {
      this._isSaving = true;
      this.passwordSetService.resetPassword(this.authToken, this._password).subscribe(state => {
        if (state) {
          this.authService.logout();
          this.router.navigate(['/login']);
        }
        else {
          this._isInError = true;
        }

        this._isSaving = false;
      },
        error => {
          this._isInError = true;
          this._isSaving = false;
          this._error = error['message'];
          throw error;
        });
    }
  }

  isMinLength() {
    if (!this._password)
      return false;

    return this._password.length >= 8;
  }

  isLettersUpper() {
    if (!this._password)
      return false;

    return this._password.match(/[A-Z]/);
  }

  isLettersLower() {
    if (!this._password)
      return false;

    return this._password.match(/[a-z]/);
  }

  isDigit() {
    if (!this._password)
      return false;

    return this._password.match(/[0-9]/);
  }

  isSpecialCharacter() {
    if (!this._password)
      return false;

    return this._password.match(/[\!\.\:\;\-\(\)\@\+\=\*\&\%\?\$\#]/);
  }

  isPasswordMatch() {
    return this._password == this._passwordRepeat;
  }

  isValid() {
    return this.isPasswordMatch() && this.isDigit() && this.isLettersLower() && this.isLettersUpper() && this.isMinLength() && this.isSpecialCharacter();
  }

}
