import { RepairType, CarPanel } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { SubstrateService } from './substrate.service';


@Injectable()
export class PanelService {
  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  private api_endpoint_repairtype = '/repairtype';
  private full_api_url_repairtype;

  private api_endpoint_panel = '/panel';
  private full_api_url_panel;

  public repairTypes: RepairType[] = [];
  public default_repair: RepairType;

  public panels: CarPanel[] = [];

  constructor(
    private dataService: DataService,
    private substrateService:SubstrateService) {
    this.full_api_url_repairtype = dataService.getUrl() + this.api_endpoint_repairtype;
    this.full_api_url_panel = dataService.getUrl() + this.api_endpoint_panel
  }


  /////////////////////////////////////////
  // Repair Type
  /////////////////////////////////////////
  getRepairTypeById(id: string):RepairType {
    for (let repType of this.repairTypes) {
      if (repType.id == id) {
        return repType;
      }
    }
  }

  buildRepairType(response): RepairType {
    let repairType = new RepairType();

    repairType.id = response['id'];
    repairType.name = response['name'];

    return repairType;
  }

  /////////////////////////////////////////
  // Panels
  /////////////////////////////////////////
  getPanelById(id: string) {
    for (let panel of this.panels) {
      if (panel.id == id) {
        return panel;
      }
    }
  }


  refreshData() {
    let isLoaded_reptypes = new BehaviorSubject(false);
    let isLoaded_panels = new BehaviorSubject(false);

    this.dataService.getRequest(this.full_api_url_repairtype).subscribe(response => {
      if (response && response['data']) {
        this.repairTypes = [];

        for (let repType of response['data']) {
          let builtType = this.buildRepairType(repType);
          this.repairTypes.push(builtType);

          //if default is specified in the database
          if (repType['is_default']) {
            this.default_repair = builtType;
          }
        }

        //if default was not specified, take first in list
        if (!this.default_repair) {
          this.default_repair = this.repairTypes[0];
        }

        isLoaded_reptypes.next(true);
      }
    });

    //wait until the substrates are loaded
    this.substrateService.initialised.distinctUntilChanged().subscribe(status => {
      if (status) {
        this.dataService.getRequest(this.full_api_url_panel).subscribe(response => {
          if (response) {
            this.panels = [];

            for (let res_panel of response['data']) {
              let panel = new CarPanel();
              panel.id = res_panel['id'];
              panel.is_outside = res_panel['is_outside'];
              panel.name = res_panel['name'];
              panel.surface_in = res_panel['surface_inside'];
              panel.surface_out = res_panel['surface_outside'];

              this.substrateService.getSubstrate(res_panel['default_substrate']).subscribe(substrate => {
                panel.default_substrate = substrate;
              });

              this.panels.push(panel);
            }

            isLoaded_panels.next(true);
          }

        });
      }
    });


    //if both panels and repair types is loaded
    isLoaded_panels.subscribe(panels_status => {
      if (panels_status) {
        isLoaded_reptypes.subscribe(reptypes_status => {
          if (reptypes_status) {
            this.lastRefresh = new Date();
            this.initialised.next(true);
          }
        })
      }
    });

  }
}
