import { ProductService } from './product.service';
import { Order, OrderItem } from './../../../model/model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(public productService: ProductService) { }

  buildEmptyOrder() {
    let order: Order = new Order();
    order.items = [];

    for (let product of this.productService.products) {
      for (let sku of product.skus) {
        let item:OrderItem = new OrderItem();
        item.product = product;
        item.sku = sku;

        order.items.push(item);
      }
    }

    return order;
  }
}
