import { NgModule, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { AuthTokenInterceptor } from './services/auth-token.interceptor';
import { GlobalErrorHandler } from './services/global-error-handler';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UiBlockService } from './services/ui-block.service';
import { ExcelService } from './services/excel.service';
import { ScaleService } from './services/scale.service';
import { SecurityRightService } from './services/security-right.service';
import { MixRatioService } from './services/mix-ratio.service';
import { BodyshopService } from './services/bodyshop.service';
import { PasswordSetService } from './services/password-set.service';
import { ManageUserService } from './services/manage-user.service';
import { ErrorService } from './services/error.service';
import { CountryService } from './services/country.service';
import { PanelService } from './services/panel.service';
import { CoatService } from './services/coat.service';
import { BatchService } from './services/batch.service';
import { JobService } from './services/job.service';
import { InventoryService } from './services/inventory.service';
import { VariantService } from './services/variant.service';
import { ColourService } from './services/colour.service';
import { CacheService } from './services/cache.service';
import { ProductService } from './services/product.service';
import { VehicleService } from './services/vehicle.service';
import { UserService } from './services/user.service';
import { LegalTermsService } from './services/legal-terms.service';
import { DataService } from './services/data.service';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { MaterialRequestService } from './services/material-request.service';
import { OrderService } from './services/order.service';
import { PriceListService } from './services/price-list.service';


@NgModule({})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AuthService,
        AuthGuard,
        UserService,
        DataService,
        ScaleService,
        ExcelService,
        UiBlockService,
        VehicleService,
        ProductService,
        CacheService,
        ColourService,
        VariantService,
        InventoryService,
        JobService,
        BatchService,
        CoatService,
        PanelService,
        CountryService,
        ErrorService,
        ManageUserService,
        PasswordSetService,
        BodyshopService,
        MixRatioService,
        LegalTermsService,
        SecurityRightService,
        MaterialRequestService,
        OrderService,
        PriceListService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
        { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } }
      ]
    };
  }
}
