import { Product, Coat, ApplicationTechnique, Substrate, CoatClass } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { applySourceSpanToStatementIfNeeded } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class CoatService {

  ////////////////////////////////////////////////
  // Coats
  ///////////////////////////////////////////////
  private coat_api_endpoint = '/coat';
  private coat_full_api_url;
  public coats: Coat[] = []
  private coat_initialised = new BehaviorSubject(false);

  ////////////////////////////////////////////////
  // Application Technique
  ///////////////////////////////////////////////
  private applicationTechnique_api_endpoint = '/applicationtechnique';
  private applicationTechnique_full_api_url;
  public applicationTechniques: ApplicationTechnique[] = [];
  private applicationTechnique_initialised = new BehaviorSubject(false);

  public default_applicaitonTechnique: ApplicationTechnique;

  ////////////////////////////////////////////////
  // Coat Class
  ///////////////////////////////////////////////
  private coatClass_api_endpoint = '/coat-class';
  private coatClass_full_api_url;
  public coatClasses: CoatClass[] = [];
  private coatClasses_initialised = new BehaviorSubject(false);

  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  constructor(public dataService: DataService) {
    //coats
    this.coat_full_api_url = dataService.getUrl() + this.coat_api_endpoint;

    //applicationTechnique
    this.applicationTechnique_full_api_url = dataService.getUrl() + this.applicationTechnique_api_endpoint;

    //coatClass
    this.coatClass_full_api_url = dataService.getUrl() + this.coatClass_api_endpoint;
  }

  getCoatById(id) {
    for (let coat of this.coats) {
      if (coat.id == id) {
        return coat;
      }
    }
  }

  getCoatsByTypeId(typeId: string) {
    let values = [];

    if (this.coats) {
      for (let coat of this.coats) {
        if (coat.type == typeId) {
          values.push(coat);
        }
      }
    }

    return values;
  }

  getApplicationTechniqueById(id) {
    for (let apptech of this.applicationTechniques) {
      if (apptech.id == id) {
        return apptech;
      }
    }
  }

  getCoatClassById(id:string){
    for (let cls of this.coatClasses){
      if(cls.id == id){
        return cls;
      }
    }

    return undefined;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);
    this.coats = [];

    ////////////////////////////////////////////////
    // Application Technique
    ///////////////////////////////////////////////
    let appTech_sub = this.dataService.getRequest(this.applicationTechnique_full_api_url).subscribe(response => {
      if (response && response['data']) {
        this.applicationTechniques = [];
        for (let item of response['data']) {
          let appType: ApplicationTechnique = new ApplicationTechnique();
          appType.id = item['id'];
          appType.name = item['name'];
          appType.is_default = item['is_default'];

          //set default application technique
          if (appType.is_default) {
            this.default_applicaitonTechnique = appType;
          }

          this.applicationTechniques.push(appType);
        }

        //if not default is specified in the data, set the first value
        if (!this.default_applicaitonTechnique && this.applicationTechniques.length > 0) {
          this.default_applicaitonTechnique = this.applicationTechniques[0];
        }

        this.applicationTechnique_initialised.next(true);
        appTech_sub.unsubscribe();
      }
    });

    ////////////////////////////////////////////////
    // Coats
    ///////////////////////////////////////////////
    let coats_sub = this.applicationTechnique_initialised.subscribe(state => {
      if (state) {
        this.dataService.getRequest(this.coat_full_api_url).subscribe(response => {
          if (response && response['data']) {
            this.coats = [];
            for (let item of response['data']) {
              let coat: Coat = new Coat();
              coat.id = item['id'];
              coat.name = item['name'];
              coat.code = item['code'];
              coat.order = item['order'];
              coat.type = item['type'];

              this.coats.push(coat);
            }

            this.coat_initialised.next(true);
            coats_sub.unsubscribe();
          }
        });
      }
    });

    ////////////////////////////////////////////////
    // CoatClass
    ///////////////////////////////////////////////
    let cclass_sub = this.dataService.getRequest(this.coatClass_full_api_url).subscribe(response => {
      if (response && response['data']) {
        this.coatClasses = [];
        for (let item of response['data']) {
          let coatClass: CoatClass = new CoatClass();
          coatClass.id = item['id'];
          coatClass.name = item['name'];
          this.coatClasses.push(coatClass);
        }

        this.coatClasses_initialised.next(true);
        cclass_sub.unsubscribe();
      }
    });



    let sub1 = this.coat_initialised.subscribe(coat_state => {
      if (coat_state) {
        let sub2 = this.applicationTechnique_initialised.subscribe(app_state => {
          if (app_state) {
            let sub3 = this.coatClasses_initialised.subscribe(class_state => {
              if(class_state){
                this.lastRefresh = new Date();
                this.initialised.next(true);
                //sub1.unsubscribe();
                //sub2.unsubscribe();
                //sub3.unsubscribe();
              }
            });
          }
        });
      }
    });
  }
}