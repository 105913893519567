import { BodyshopService } from './bodyshop.service';
import { ProductService } from './product.service';
import { MaterialRequest, MaterialRequestItem, ProductSku, Bodyshop } from './../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialRequestService {
  private api_endpoint = '/material-request';
  private full_api_url;
  private api_endpoint_query = '/material-request-query';
  private full_api_url_query;

  constructor(public dataService: DataService,
    public productService: ProductService,
    public userService: UserService,
    public bodyshopSerivice: BodyshopService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
    this.full_api_url_query = dataService.getUrl() + this.api_endpoint_query;
  }

  getMaterialRequest(id: number): Observable<MaterialRequest> {
    return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
      return this.buildMaterialRequest(response);
    });
  }

  queryMaterialRequests(params): Observable<MaterialRequest[]> {
    let requestUrl = this.full_api_url_query;
    let requestBody = params;

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      if (response && response['data']) {
        let matRequests = [];

        for (let resRequest of response['data']) {
          let createdRequest = this.buildMaterialRequest(resRequest);
          if (createdRequest) {
            matRequests.push(createdRequest);
          }
        }

        return matRequests;
      }
    });
  }

  saveMaterialRequest(materialRequest: MaterialRequest): Observable<MaterialRequest> {
    let request;
    let requestUrl = this.full_api_url;
    let requestBody = {
      'items': [],
      'bodyshop': materialRequest.bodyshop.id
    };

    if (materialRequest.date_target_delivery) {
      requestBody['date_target_delivery'] = materialRequest.date_target_delivery.toISOString();
    }

    for (let item of materialRequest.items) {
      let reqItem = {
        'product': item.product.id,
        'product_is_kansai': item.product.is_kansai,
        'sku': {
          'pack_size': item.sku.pack_size,
          'code': item.sku.code
        },
        'quantity': item.quantity
      };

      requestBody.items.push(reqItem);
    }


    if (materialRequest.id) {
      requestUrl += "/" + materialRequest.id;
      request = this.dataService.putRequest(requestUrl, requestBody);
    }
    else {
      request = this.dataService.postRequest(requestUrl, requestBody)
    }

    return request.map(response => {
      return this.buildMaterialRequest(response);
    });
  }

  deleteMaterialRequest(materialRequest_id: number): Observable<MaterialRequest> {
    let requestUrl = this.full_api_url + "/" + materialRequest_id;

    return this.dataService.deleteRequest(requestUrl).map(response => {
      return this.buildMaterialRequest(response);
    });
  }

  populateUsers(materialRequests: MaterialRequest[]) {
    let usersToFetch = {};

    for (let mr of materialRequests) {
      //user_created
      let userKey = '' + mr.user_created_id;

      if (!usersToFetch[userKey]) {
        usersToFetch[userKey] = {
          'user_id': mr.user_created_id,
          'created': [],
          'deleted': [],
          'modified': []
        };
      }

      usersToFetch[userKey]['created'].push(mr);

      if (mr.user_deleted_id) {
        //user_deleted
        userKey = '' + mr.user_deleted_id;
        if (!usersToFetch[userKey]) {
          usersToFetch[userKey] = {
            'user_id': mr.user_deleted_id,
            'created': [],
            'deleted': [],
            'modified': []
          };
        }

        usersToFetch[userKey]['deleted'].push(mr);
      }

    }

    for (let key of Object.keys(usersToFetch)) {
      let user_id = usersToFetch[key]['user_id'];

      this.userService.getSingleUser(user_id).subscribe(user => {
        for (let mr of usersToFetch[key]['created']) {
          mr.user_created = user;
        }
        for (let mr of usersToFetch[key]['deleted']) {
          mr.user_deleted = user;
        }
      });
    }
  }



  populateBodyshops(materialRequests: MaterialRequest[]) {
    let bshopsToFetch = {};

    for (let mr of materialRequests) {
      let bshopKey = bshopsToFetch['' + mr.bodyshop_id];
      if (!bshopsToFetch[bshopKey]) {
        bshopsToFetch[bshopKey] = [];
      }

      bshopsToFetch[bshopKey].push(mr);
    }

    for (let key of Object.keys(bshopsToFetch)) {
      let list = bshopsToFetch[key];

      this.bodyshopSerivice.getBodyshopById(list[0].bodyshop_id).subscribe(bshop => {
        for (let mr of list) {
          mr.bodyshop = bshop;
        }
      });
    }
  }

  buildMaterialRequest(response): MaterialRequest {
    if (response) {
      let materialRequest = new MaterialRequest();

      materialRequest.id = response['id'];
      materialRequest.bodyshop_id = response['bodyshop'];
      materialRequest.is_locked = response['is_locked'];
      materialRequest.is_deleted = response['is_deleted'];
      materialRequest.date_target_delivery = response['date_target_delivery'];
      materialRequest.date_created = new Date(response['ts_created']);
      materialRequest.date_modified = response['ts_modified'] ? new Date(response['ts_modified']) : null;
      materialRequest.date_deleted = response['ts_deleted'] ? new Date(response['ts_deleted']) : null;
      materialRequest.user_created_id = response['user_created'];
      materialRequest.user_modified_id = response['user_modified'];
      materialRequest.user_deleted_id = response['user_deleted'];
      materialRequest.items = [];

      for (let resItem of response['items']) {
        let item = new MaterialRequestItem();
        item.product = this.productService.getProductById(resItem['product']);
        item.quantity = resItem['quantity'];

        let sku = new ProductSku();
        sku.code = resItem['sku']['code'];
        sku.pack_size = resItem['sku']['pack_size'];

        item.sku = sku;

        materialRequest.items.push(item);
      }

      return materialRequest;
    }

    return null;
  }
}
