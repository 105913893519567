<div #uiBlockDiv>

</div>
<div #uiBlockStorage style="display:none;">
    <div id="block-ui-for-load">
        <div class="loader-logo">
            <img src="/assets/kansai_global_logo.svg">
        </div>
        <div class="loader">
            <mat-spinner [diameter]="30" class="load-spinner"></mat-spinner>
            Loading the App...
        </div>
    </div>
</div>

<router-outlet></router-outlet>

<ng-container *ngIf="isLoggedIn()"></ng-container>