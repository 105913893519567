import { MatDialogModule } from '@angular/material/dialog';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { RegistrationHomeComponent } from './components/registration-home/registration-home.component';
import { NgModule } from '@angular/core';
import { routing } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AppComponent } from './components/app/app.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { DialogErrorMessageComponent } from './components/dialogs/dialog-error-message/dialog-error-message.component';

@NgModule({
  declarations: [
    AppComponent,
    RegistrationHomeComponent,
    PasswordResetComponent,
    LoginComponent,
    LandingPageComponent,
    TermsAndConditionsComponent,
    PrivacyComponent,
    DialogErrorMessageComponent
  ],
  entryComponents: [ 
    DialogErrorMessageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    SharedModule.forRoot(),
    RouterModule.forRoot([]),
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule {  }
