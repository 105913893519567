import { ActivatedRoute } from '@angular/router/';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-home',
  templateUrl: './registration-home.component.html',
  styleUrls: ['./registration-home.component.css']
})
export class RegistrationHomeComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute) {

      console.log(this);

    if (this.route.children.length == 0) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {
  }

}
