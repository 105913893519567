<div id="container">
    <div class="vertical-spacer-top">
        <div id="logo-k"></div>
    </div>
    <div class="form">
        <div class="title">
            Choose a Password 
        </div>
        <ng-container *ngIf="_isChecking">
            <mat-spinner diameter="70"></mat-spinner>
        </ng-container>

        <ng-container *ngIf="!_isChecking">
            <div *ngIf="_isInvalid">
                This link is not valid.
            </div>
            <div class="sidebyside" *ngIf="!_isInvalid">
                <div class="text">
                    To set a new password for your account, please enter it twice in the fields below.
                    Make sure they satisfy the complexity requirements
                    <table class="table">
                        <tr>
                            <td>
                                <mat-icon class="ok" *ngIf="isMinLength()">done</mat-icon>
                                <mat-icon class="nok" *ngIf="!isMinLength()">error</mat-icon>
                            </td>
                            <td>Minimum length: 8 characters</td>
                        </tr>
                        <tr>
                            <td>
                                <mat-icon class="ok" *ngIf="isLettersUpper()">done</mat-icon>
                                <mat-icon class="nok" *ngIf="!isLettersUpper()">error</mat-icon>
                            </td>
                            <td>Contains upper case letters (A-Z)</td>
                        </tr>
                        <tr>
                            <td>
                                <mat-icon class="ok" *ngIf="isLettersLower()">done</mat-icon>
                                <mat-icon class="nok" *ngIf="!isLettersLower()">error</mat-icon>
                            </td>
                            <td>Contains lower case letters (a-z)</td>
                        </tr>
                        <tr>
                            <td>
                                <mat-icon class="ok" *ngIf="isDigit()">done</mat-icon>
                                <mat-icon class="nok" *ngIf="!isDigit()">error</mat-icon>
                            </td>
                            <td>Contains digits (0-9)</td>
                        </tr>
                        <tr>
                            <td>
                                <mat-icon class="ok" *ngIf="isSpecialCharacter()">done</mat-icon>
                                <mat-icon class="nok" *ngIf="!isSpecialCharacter()">error</mat-icon>
                            </td>
                            <td>Contains special characters (!.:;-()@+=*&%?$#)</td>
                        </tr>
                    </table>
                </div>
                <div class="field-container">
                    <div class="fields">
                        <mat-form-field class="high-opacity" appearance="fill">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" #password [(ngModel)]="_password" tabindex="1">
                            <button mat-button matSuffix mat-icon-button *ngIf="password.type=='password'" (click)="password.type='text'">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-button matSuffix mat-icon-button *ngIf="password.type=='text'" (click)="password.type='password'">
                                <mat-icon>visibility_off</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field class="high-opacity" appearance="fill">
                            <mat-label>Re-enter Password</mat-label>
                            <input matInput type="password" #passwordRepeat [(ngModel)]="_passwordRepeat" tabindex="2">
                            <button mat-button matSuffix mat-icon-button *ngIf="passwordRepeat.type=='password'"
                                (click)="passwordRepeat.type='text'">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-button matSuffix mat-icon-button *ngIf="passwordRepeat.type=='text'" (click)="passwordRepeat.type='password'">
                                <mat-icon>visibility_off</mat-icon>
                            </button>
                            <mat-hint *ngIf="passwordRepeat.value && !isPasswordMatch()" class="hint">Passwords do not match!</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="actions">
                        <button mat-raised-button class="submit-button" [class.disabled-button]="submitButton.disabled"
                            color="primary" [disabled]="!isValid() || _isSaving" (click)="submit()" #submitButton>
                            <ng-container *ngIf="_isSaving"><mat-spinner diameter="20" class="spinner"></mat-spinner></ng-container>
                            <ng-container *ngIf="!_isSaving">Save</ng-container>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>


    </div>
    <div class="vertical-spacer"></div>
</div>