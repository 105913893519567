import { Toolkit } from '../toolkit/toolkit';

export class Currency {
    public id: string;
    public iso_4217: string;
    public name: string;
}

export class Unit {
    public id: string;
    public name_singular: string;
    public name_plural: string;
}

export class Bodyshop {
    public id: number;
    public name: string;
    public id_custom: string;
    public tenant: Tenant;
    public tenant_id: number;
    public is_active: boolean;
    public is_deleted: boolean;
    public address = {};

    public isEnabled() {
        return this.is_active && !this.is_deleted;
    }
}

export class Tenant {
    public id: number;
    public customer_id: string;
    public name: string;
    public currency: Currency;
    public currency_id: string;
    public country: Country;
    public country_id: string;
    public kansaiCompany: KansaiCompany;
    public is_kansai: boolean = false;
    public account_manager: User;
    public account_manager_id: number;
    public technical_support: User;
    public technical_support_id: number;
}

export class KansaiCompany {
    public id: number;
    public name: string;
}

export class Country {
    public id: string;
    public iso_3166: string;
    public name_common: string;
    public name_full: string;
}

export class User {
    public id: number;
    public email: string;
    public name: string;
    public date_birth: Date;
    public employee_nb: string;
    public bodyshops: Bodyshop[] = []
    public bodyshop_id: number;
    public tenant: Tenant;
    public currentBodyshop: Bodyshop;
    public is_tenantAdmin: boolean = false;
    public is_active: boolean = false;
    public is_kansai: boolean = false;
    public is_global: boolean = false;
    public is_developer: boolean = false;
    public is_allowed_preprod: boolean = false;
    public permissions: any = {};
    public tenant_level: string = 'normal';
    public tenant_is_viewCost: boolean = false;
    public acceptedTerms: LegalTermsAcceptance[] = [];

    public getAcceptedTermsById(id: number): LegalTermsAcceptance {
        for (let term of this.acceptedTerms) {
            if (term.terms_id == id)
                return term;
        }

        return null;
    }

    public hasMinimumLevel(targetLevel: string) {
        return this.enumLevel(targetLevel) <= this.enumLevel(this.tenant_level);
    }

    private enumLevel(level) {
        if (level == "tenant")
            return 30;
        if (level == "bodyshop")
            return 20;
        if (level == "normal")
            return 10;
    }
}

export class LegalTerms {
    public id: number;
    public ts_created: Date;
    public ts_valid_from: Date;
    public ts_modified: Date;
    public type: string;
    public scope: string;
    public text_html: string;
}

export class LegalTermsAcceptance {
    public terms_id: number;
    public timestamp: Date;
}


export class SecurityRole {
    public id: string;
    public code: string;
    public name: string;
    public rights: SecurityRight[] = [];
}

export class SecurityRight {
    public id: string;
    public domain: string;
    public action: string;
    public scope_enduser: boolean;
    public scope_kansaiCompany: boolean;
    public scope_global: boolean;

    public bodyshop_ids: number[] = [];
    public tenant_id: number;
    public kansaiCompany_id: number;
    public is_globalAdmin: boolean = false;
}

export class Colour {
    public id: number;
    public carManufacturer: CarManufacturer;
    public name: string;
    public _searchString: string;
    public code: string;
    public names: string[];
}

export class Coat {
    public id: string;
    public name: string;
    public order: number;
    public code: string;
    public type: string;
    public applicationTechniques: ApplicationTechnique[] = [];
}

export class CoatSystem {
    public id: number;
    public product_type: ProductType;
    public coats: Coat[] = [];

}

export class FormulaComponent {
    public amount_kg: number = 0.0;
    public ratio: number;
    public error: number = 0.0;
    public product: Product;
    public product_category: ProductCategory;

    constructor(product?: Product, ratio?: number, error?: number) {
        if (product)
            this.product = product;
        if (ratio)
            this.ratio = ratio;
        if (error)
            this.error = error;
    }
}

export class RepairType {
    public id: string;
    public name: string;
}

export class Substrate {
    public id: string;
    public name: string;
}

export class Variant {
    public id: number;
    public name: string;
    public code: object;
    public version: string;
    public is_active: boolean;
    public colour: Colour;
    public product_type: ProductType;
    public created_user: User;
    public system: VariantSystem;
    public coats: VariantCoat[] = [];
    public country: Country;
    public bodyshop: Bodyshop;

    getListOfProducts(): Product[] {
        let pList: Product[] = [];

        for (let coat of this.coats) {
            for (let component of coat.formula) {
                let found = false;

                for (let product of pList) {
                    if (product.id == component.product.id) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    pList.push(component.product);
                }
            }
        }

        pList.sort((a: Product, b: Product) => {
            if (a.code_short < b.code_short)
                return -1;
            if (a.code_short > b.code_short)
                return 1;
            return 0;
        });

        return pList;
    }

    hasTopCoat() {
        return this.hasCoatById('top');
    }

    hasBaseCoat() {
        return this.hasCoatById('base');
    }

    hasElsCoat() {
        return this.hasCoatById('els');
    }

    hasUnderCoat() {
        return this.hasCoatById('under');
    }

    hasCoatById(id: string) {
        for (let coat of this.coats) {
            if (coat.coat.id == id)
                return true;
        }

        return false;
    }

    getCoat(coat: Coat) {
        return this.getCoatByCoatId(coat.id);
    }

    getCoatByCoatId(id) {
        for (let c of this.coats) {
            if (c.coat.id == id) {
                return c;
            }
        }
    }

    sortFormulasByCoat(): VariantCoat[] {
        return this.coats.sort((a: VariantCoat, b: VariantCoat) => {
            if (a.coat && b.coat)
                return a.coat.order - b.coat.order;
            else
                return 0;
        });
    }

    getCoatsInverseSort() {
        let newArray = [];

        for (let vc of this.coats) {
            newArray.push(vc);
        }

        return newArray.sort((a: VariantCoat, b: VariantCoat) => {
            return b.coat.order - a.coat.order;
        });;
    }

    toFullString(): string {
        if (this.colour && this.colour.carManufacturer)
            return this.colour.carManufacturer.name + " | " + this.colour.code + " - " + this.colour.name + " | " + this.name;
    }

    isWithRatios(): boolean {
        for (let coat of this.coats) {
            for (let component of coat.formula) {
                if (component.ratio == null) {
                    return false;
                }
            }
        }

        return true;
    }

    cleanUp() {
        let coats = [];

        for (let c of this.coats) {
            if (c.formula.length > 0) {
                coats.push(c);
            }
        }

        this.coats = coats;
    }
}

export class VariantCoat {
    public coat: Coat;
    public class: CoatClass;
    public formula: FormulaComponent[] = [];
    public origin_batch_id: number;
}

export class VariantCoatAttempt {
    public user: User;
    public timestamp: Date = new Date();
    public formula: FormulaComponent[] = [];
    public f_index: number;
    public batch_test: Batch;
}

export class VariantAttempt {
    public variant: Variant;
    public timestamp: Date = new Date();
    public user: User;
}


export class MatchSeries {
    public start: FormulaComponent[] = [];
    public batch: Batch;
    public batch_id: number;
    public result: FormulaComponent[] = [];
    public coat: Coat;
    public timestamp: Date = new Date();
    public attempts: MatchAttempt[] = [];

    getStartComponentForProduct(product: Product): FormulaComponent {
        for (let component of this.start) {
            if (component.product.id == product.id) {
                return component;
            }
        }
    }

    getAllProducts(): Product[] {
        let products: Product[] = [];

        for (let component of this.start) {
            let found = false;
            for (let product of products) {
                if (component.product.id == product.id) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                products.push(component.product);
            }
        }

        if (this.batch) {
            for (let component of this.batch.formula) {
                let found = false;
                for (let product of products) {
                    if (component.product.id == product.id) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    products.push(component.product);
                }
            }
        }


        for (let attempt of this.attempts) {
            if (attempt.batch) {
                for (let component of attempt.batch.formula) {
                    let found = false;
                    for (let product of products) {
                        if (component.product.id == product.id) {
                            found = true;
                            break;
                        }
                    }

                    if (!found) {
                        products.push(component.product);
                    }
                }
            }

        }

        return products;
    }
}

export class MatchAttempt {
    public formula: FormulaComponent[] = [];
    public batch: Batch;
    public batch_id: number;
    public timestamp: Date = new Date();

    getComponentForProduct(product: Product): FormulaComponent {
        for (let component of this.formula) {
            if (component.product.id == product.id) {
                return component;
            }
        }
    }
}

export class ColourMatch {
    public id: number;
    public colour: Colour;
    public colour_code: string;
    public colour_name: string;
    public car_manufacturer: CarManufacturer
    public job_id: number;
    public variant: Variant;
    public variant_init: Variant;
    public batches_dev: any = {};
    public history: VariantAttempt[] = [];
    public is_active: boolean = true;
    public is_locked: boolean = false;

    public matchSeries: MatchSeries[] = [];

    getSeriesForCoat(coat: Coat): MatchSeries[] {
        let series = [];

        for (let s of this.matchSeries) {
            if (s.coat.id == coat.id) {
                series.push(s);
            }
        }

        return series;
    }

    setVariant(variant: Variant) {
        if (this.variant) {
            let historyEntry = new VariantAttempt();
            historyEntry.variant = this.variant;
            this.history.push(historyEntry);
        }
        this.variant = variant;
    }

    relinkBatches(batches: Batch[]) {
        //link batches to colour matches

        for (let series of this.matchSeries) {
            for (let batch of batches) {
                if (series.batch_id == batch.id) {
                    series.batch = batch;
                    break;
                }
            }

            for (let attempt of series.attempts) {
                for (let batch of batches) {
                    if (attempt.batch_id == batch.id) {
                        attempt.batch = batch;
                        break;
                    }
                }
            }
        }
    }
}


export class Job {
    public id: number;
    public car_license_plate: string;
    public car_manufacturer: CarManufacturer;
    public car_model: CarModel;
    public car_model_text: string;
    public car_year: number;
    public car_chassis_number: string;
    public car_country: Country;
    public id_custom: string;
    public customer_id: string;
    public customer_name: string;
    public customer_phone: string;
    public bodyshop: Bodyshop;
    public comments: string;
    public panels: JobPanel[] = [];
    public batches: Batch[] = [];
    public colours: ColourMatch[] = [];
    public benchmark_amount: number;
    public date_created: Date;
    public date_modified: Date;
    public user_id_created: number;
    public user_id_modified: number;
    public date_closed: Date;
    public date_discarded: Date;
    public cost: number;
    public quantity_kg: number;
    public customProductConsumption: JobCustomProduct[] = [];
    public duplicate_parent: any;
    public duplicate_children: any[] = [];

    isEditable() {
        return !this.date_closed && !this.date_discarded;
    }

    relinkBatches() {
        for (let colour of this.colours) {
            colour.relinkBatches(this.batches);
        }
    }

    addBatch(batch: Batch) {
        for (let b of this.batches) {
            if (b.id == batch.id) {
                this.batches.splice(this.batches.indexOf(b), 1);
                break;
            }
        }

        this.batches.push(batch);
        this.sortBatches();
        this.relinkBatches();
    }

    getAllUsersIds(): number[] {
        let user_ids = new Set([this.user_id_created]);

        if (this.user_id_modified)
            user_ids.add(this.user_id_modified);

        for (let batch of this.batches) {
            user_ids.add(batch.user_id_created);

            if (batch.user_id_modified)
                user_ids.add(batch.user_id_modified);
        }

        return Array.from(user_ids);
    }

    getCustomProduct(id) {
        for (let product of this.customProductConsumption) {
            if (product.product.id === id) {
                return product;
            }
        }

        return undefined;
    }

    getCostForMatchingBatches() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (batch.is_matchingBatch) {
                total += batch.getTotalCost();
            }
        }

        return total;
    }

    getConsumptionForMatchingBatches() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (batch.is_matchingBatch) {
                total += batch.getTotalQuantityInKg();
            }
        }

        return total;
    }

    getTotalCostForMixBatches() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch) {
                total += batch.getTotalCost();
            }
        }

        return total;
    }

    getBatchById(id: number) {
        let batch = undefined;

        for (let b of this.batches) {
            if (b.id == id) {
                return b;
            }
        }

        return batch;
    }

    replaceBatch(batch: Batch) {
        for (let b of this.batches) {
            if (b.id == batch.id) {
                this.batches.splice(this.batches.indexOf(b), 1, batch);
                break;
            }
        }
    }

    getBatchesForColourMatch(cmatch: ColourMatch): Batch[] {
        let batches = [];

        for (let batch of this.batches) {
            if (batch.colourMatch_id == cmatch.id) {
                batches.push(batch);
            }
        }

        return batches;
    }

    getBatchesForColourMatchAndCoat(cmatch: ColourMatch, coat: Coat): Batch[] {
        let batchesForCmatch = this.getBatchesForColourMatch(cmatch);
        let batches = []

        for (let batch of batchesForCmatch) {
            if (batch.coat.id == coat.id) {
                batches.push(batch);
            }
        }

        return batches;
    }

    getBaseConsumptionForSimpleCoat(coat: Coat, appTech: ApplicationTechnique, substrate?: Substrate, colour?: Colour) {
        let total = 0.0;
        let appTech_id = appTech ? appTech.id : 'std';

        for (let batch of this.batches) {
            if (batch.coat.id == coat.id) {
                if (batch.applicationTechnique.id == appTech_id) {
                    if (substrate && batch.substrate && batch.substrate.id != substrate.id) {
                        continue
                    }

                    if (colour && batch.colour_id != colour.id)
                        continue;

                    total += batch.getTotalBaseQuantityInKg();
                }
            }
        }

        return total;
    }

    getColourMatchById(id: number) {
        for (let cmatch of this.colours) {
            if (cmatch.id == id) {
                return cmatch;
            }
        }
    }

    getTargetVsActualCost() {
        if (this.benchmark_amount) {
            return Toolkit.precision(this.benchmark_amount - this.getTotalCost(), 5)
        }
        else {
            return undefined;
        }
    }

    getConsumptionByProduct() {
        let result = {};

        for (let batch of this.batches) {
            for (let component of batch.getAllComponents()) {
                if (component.product) {
                    if (!(component.product.id in result)) {
                        result[component.product.id] = 0.0;
                    }

                    result[component.product.id] += component.quantity_kg;
                }
            }
        }

        return result;
    }


    addColourMatch(cMatch: ColourMatch) {
        if (!this.containsColour(cMatch.colour)) {
            this.colours.push(cMatch);
        }
    }

    getUnfinishedBatchesForCoat(coat: Coat, colour?: ColourMatch) {
        let batches: Batch[] = [];

        for (let batch of this.batches) {
            if (batch.coat.id == coat.id) {
                let ok = true;
                if (colour) {
                    ok = (colour.id == batch.colourMatch_id);
                }

                if (ok) {
                    if (!batch.is_discarded && !batch.is_locked && !batch.is_matchingBatch) {
                        batches.push(batch);
                    }
                }
            }
        }

        return batches;
    }


    getBatchesForCoat(coat: Coat, colour?: Colour): Batch[] {
        let batches = [];

        for (let batch of this.batches) {
            if (batch.coat && batch.coat.id == coat.id) {

                if (colour) {
                    if (colour.id == batch.colour_id) {
                        batches.push(batch);
                    }
                }
                else {
                    batches.push(batch);
                }
            }
        }

        return batches;
    }

    getBatchesForCoatAndSubstrate(coat: Coat, substrate: Substrate, colour?: Colour): Batch[] {
        let batches = [];

        for (let batch of this.getBatchesForCoat(coat, colour)) {
            if (!substrate && !batch.substrate) {
                batches.push(batch);
            }
            else if (batch.substrate && substrate && batch.substrate.id == substrate.id) {
                batches.push(batch);
            }
        }

        return batches;
    }

    getBatchesForCoatAndSubstrateAndAppTech(coat: Coat, substrate: Substrate, appTech: ApplicationTechnique, colour?: Colour): Batch[] {
        let batches = [];

        for (let batch of this.getBatchesForCoatAndSubstrate(coat, substrate, colour)) {
            if (!appTech && !batch.applicationTechnique) {
                batches.push(batch);
            }
            else if (batch.applicationTechnique && appTech && batch.applicationTechnique.id == appTech.id) {
                batches.push(batch);
            }
        }

        return batches;
    }

    containsColour(colour: Colour) {
        let found = false;

        if (colour.id) {
            for (let cmatch of this.colours) {
                if (cmatch.colour && cmatch.colour.id == colour.id) {
                    if (cmatch.is_active) {
                        found = true;
                        break;
                    }
                }
            }
        }

        return found;
    }

    deleteColour(colourMatch: ColourMatch) {
        let foundJobColour: ColourMatch = undefined;

        //find item with same colour id
        for (let jc of this.colours) {
            if (jc.colour.id == colourMatch.colour.id) {
                foundJobColour = jc;
                break;
            }
        }

        //set as inactive
        foundJobColour.is_active = false;

        //splice array
        if (foundJobColour) {
            this.colours.splice(this.colours.indexOf(foundJobColour), 1);
        }
    }

    getActiveColours(): ColourMatch[] {
        let cols = [];

        for (let colour of this.colours) {
            if (colour.is_active)
                cols.push(colour);
        }

        return cols;
    }

    getTotalCost() {
        let total = 0.0;
        for (let batch of this.batches) {
            total += batch.getTotalCost();
        }

        return Toolkit.precision(total, 2);
    }

    getTotalCostAll() {
        return this.getTotalCost() + this.getTotalCostCustomProducts();;
    }

    getTotalCostCustomProducts() {
        let total = 0.0;

        for (let item of this.customProductConsumption) {
            total += item.unit_cost * item.quantity;
        }

        return total;
    }

    getTotalCostWaste() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && batch.is_discarded) {
                total += batch.getTotalCost();
            }
        }

        return total;
    }

    getTotalConsumptionWaste() {
        let total = 0.0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && batch.is_discarded) {
                total += batch.getTotalQuantityInKg();
            }
        }

        return total;
    }

    getTotalConsumption() {
        let total = 0.0;
        for (let batch of this.batches) {
            total += batch.getTotalQuantityInKg();
        }

        return Toolkit.precision(total, 5);
    }

    getTotalCostInUse() {
        let total = 0.0;
        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && !batch.is_discarded) {
                total += batch.getTotalCost();
            }
        }

        return Toolkit.precision(total, 5);
    }

    getTotalConsumptionInUse() {
        let total = 0.0;
        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && !batch.is_discarded) {
                total += batch.getTotalQuantityInKg();
            }
        }

        return Toolkit.precision(total, 5);
    }

    getCountRecalculations() {
        let total = 0;

        for (let batch of this.batches) {
            if (!batch.is_matchingBatch) {
                total += batch.recalculations.length;
            }
        }

        return total;
    }

    //base consumption for batches

    getBaseConsumptionByCoat(coat: Coat) {
        let total = 0.0;
        for (let batch of this.batches) {
            if (batch.coat && batch.coat.id == coat.id)
                total += batch.getTotalBaseQuantityInKg();
        }
        return Toolkit.precision(total, 5);
    }
    getBaseConsumptionInUseByCoat(coat: Coat) {
        let total = 0.0;
        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && !batch.is_discarded) {
                if (batch.coat && batch.coat.id == coat.id)
                    total += batch.getTotalBaseQuantityInKg();
            }
        }
        return Toolkit.precision(total, 5);
    }
    getBaseConsumptionWasteByCoat(coat: Coat) {
        let total = 0.0;
        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && batch.is_discarded) {
                if (batch.coat && batch.coat.id == coat.id)
                    total += batch.getTotalBaseQuantityInKg();
            }
        }
        return total;
    }
    getBaseConsumptionMatchingByCoat(coat: Coat) {
        let total = 0.0;

        for (let batch of this.batches) {
            if (batch.is_matchingBatch) {
                if (batch.coat && batch.coat.id == coat.id)
                    total += batch.getTotalBaseQuantityInKg();
            }
        }

        return total;
    }

    getCostTotalByCoat(coat: Coat) {
        let total = 0.0;
        for (let batch of this.batches) {
            if (batch.coat && batch.coat.id == coat.id) {
                for (let bc of batch.components) {
                    total += bc.quantity_kg * bc.cost_per_kg
                }
                for (let bc of batch.components_ancillary) {
                    total += bc.quantity_kg * bc.cost_per_kg
                }
            }
        }
        return Toolkit.precision(total, 5);
    }
    getCostInUseByCoat(coat: Coat) {
        let total = 0.0;
        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && !batch.is_discarded) {
                if (batch.coat && batch.coat.id == coat.id) {
                    for (let bc of batch.components) {
                        total += bc.quantity_kg * bc.cost_per_kg
                    }
                    for (let bc of batch.components_ancillary) {
                        total += bc.quantity_kg * bc.cost_per_kg
                    }
                }
            }
        }
        return Toolkit.precision(total, 5);
    }
    getCostWasteByCoat(coat: Coat) {
        let total = 0.0;
        for (let batch of this.batches) {
            if (!batch.is_matchingBatch && batch.is_discarded) {
                if (batch.coat && batch.coat.id == coat.id) {
                    for (let bc of batch.components) {
                        total += bc.quantity_kg * bc.cost_per_kg
                    }
                    for (let bc of batch.components_ancillary) {
                        total += bc.quantity_kg * bc.cost_per_kg
                    }
                }
            }
        }
        return total;
    }
    getCostMatchingByCoat(coat: Coat) {
        let total = 0.0;

        for (let batch of this.batches) {
            if (batch.is_matchingBatch) {
                if (batch.coat && batch.coat.id == coat.id) {
                    for (let bc of batch.components) {
                        total += bc.quantity_kg * bc.cost_per_kg
                    }
                    for (let bc of batch.components_ancillary) {
                        total += bc.quantity_kg * bc.cost_per_kg
                    }
                }
            }
        }

        return total;
    }



    getSelectedPanels(): JobPanel[] {
        let selected = [];

        for (let panel of this.panels) {
            if (panel.isSelected()) {
                selected.push(panel);
            }
        }

        return selected;
    }


    getVariants(): Variant[] {
        let variants = new Set<Variant>();

        for (let jobColour of this.colours) {
            if (jobColour.variant) {
                variants.add(jobColour.variant);
            }
        }

        return Array.from(variants);
    }

    getProductTypes(): ProductType[] {
        let ptypes = new Set<ProductType>();

        for (let jobColour of this.colours) {
            if (jobColour.variant) {
                ptypes.add(jobColour.variant.product_type);
            }
        }

        return Array.from(ptypes);
    }

    /*
    getAvailaleCoatsByTypeId(coatTypeId: string): Coat[] {
        let coats = new Set();

        for (let jColour of this.colours) {
            if (jColour.variant) {
                for (let coat of jColour.variant.product_type.getCoatsByTypeId(coatTypeId)) {
                    coats.add(coat);
                }
            }
        }

        return Array.from(coats);
    }
    */

    getSubstrates(): Substrate[] {
        let substrates = new Set<Substrate>();

        for (let panel of this.panels) {
            if (panel.isSelected()) {
                substrates.add(panel.substrate);
            }
        }

        return Array.from(substrates);
    }

    getApplicationTechniques(coatTypeId?: string): ApplicationTechnique[] {
        let appTechs = new Set<ApplicationTechnique>();

        for (let panel of this.panels) {
            if (panel.isSelected()) {
                for (let surface of panel.surfaces) {
                    if (surface.is_selected) {
                        for (let coat of surface.coats) {
                            if (coat.is_selected) {
                                if (coatTypeId) {
                                    if (coat.coat.type == coatTypeId) {
                                        appTechs.add(coat.application_technique);
                                    }

                                }
                                else {
                                    appTechs.add(coat.application_technique);
                                }
                            }
                        }
                    }
                }
            }
        }

        return Array.from(appTechs);
    }

    /*
    getAvailableApplicationTechniques(coatTypeId?: string): ApplicationTechnique[] {
        let appTechs = new Set();
        for (let coat of this.getAvailaleCoatsByTypeId(coatTypeId)) {
            for (let appTech of coat.applicationTechniques) {
                appTechs.add(appTech);
            }
        }

        return Array.from(appTechs);
    }
    */

    getBatchStats(batches: Batch[]) {
        let stats = {
            'sum_baseKg_target': 0.0,
            'sum_baseKg_actual': 0.0,
            'sum_totalKg_actual': 0.0,
            'sum_cost_actual': 0.0
        }

        for (let batch of batches) {
            stats['sum_baseKg_target'] += batch.quantity_target_kg;
            stats['sum_baseKg_actual'] += batch.getTotalBaseQuantityInKg();
            stats['sum_totalKg_actual'] += batch.getTotalQuantityInKg();
            stats['sum_cost_actual'] += batch.getTotalCost();
        }

        return stats;
    }

    sortColours(): void {
        this.colours.sort((a: ColourMatch, b: ColourMatch) => {
            if (a.colour.code > b.colour.code) {
                return 1;
            }
            else if (a.colour.code < b.colour.code) {
                return -1;
            }
            else {
                return 0;
            }
        });
    }

    hasSelectedPanels(): boolean {
        let result = false;
        for (let panel of this.panels) {
            result = result || panel.isSelected();
        }

        return result;
    }

    usedSubstrates(): Substrate[] {
        let substrates = new Set<Substrate>();

        for (let panel of this.panels) {
            substrates.add(panel.substrate);
        }

        return Array.from(substrates).sort();
    }


    getJobPanel(search_panel: CarPanel): JobPanel {
        let result = null;

        for (let panel of this.panels) {
            if (panel.panel.id == search_panel.id) {
                return panel;
            }
        }

        return result;
    }

    sortBatches() {
        this.batches.sort((a: Batch, b: Batch) => {
            if (a.ts_created && b.ts_created)
                return a.ts_created.getTime() - b.ts_created.getTime();
            return 0;
        });
    }
}

export class JobPanel {
    public panel: CarPanel;
    public substrate: Substrate;
    public colour: ColourMatch;
    //public colour:JobColour;
    public surfaces: JobPanelSurface[] = [];

    isSelected(): boolean {
        return this.isSurfaceInsideSelected() || this.isSurfaceOutsideSelected();
    }

    getSurfaceInside() {
        return this.getSurface(true);
    }

    getSurfaceOutside() {
        return this.getSurface(false);
    }

    isSurfaceOutsideSelected() {
        if (this.getSurfaceOutside()) {
            return this.getSurfaceOutside().is_selected;
        }
        else {
            return false;
        }
    }

    isSurfaceInsideSelected() {
        if (this.getSurfaceInside()) {
            return this.getSurfaceInside().is_selected;
        }
        else {
            return false;
        }
    }

    getSurface(inside: boolean) {
        for (let surface of this.surfaces) {
            if (surface.is_inside == inside) {
                return surface;
            }
        }

        return undefined;
    }

}

export class JobPanelCoat {
    public coat: Coat;
    public application_technique: ApplicationTechnique;
    public is_selected: boolean = false;
}

export class JobPanelSurface {
    public is_inside: boolean;
    public is_selected: boolean = false;
    public repair: RepairType;
    public coats: JobPanelCoat[] = [];
}

export class VariantSystem {
    public stages: number;
    public top: CoatClass;
    public base: CoatClass;
    public has_els: boolean = false;
    public has_under: boolean = false;

    toJson() {
        return {
            'stages': this.stages,
            'top': this.top ? this.top.id : null,
            'base': this.base ? this.base.id : null,
            'has_els': this.has_els,
            'has_under': this.has_under
        }
    }

    getClassForCoat(coat: Coat) {
        if (coat.id == 'top')
            return this.top;
        if (coat.id == 'base')
            return this.base;
    }
}


export class Batch {
    public id: number;
    public quantity_target_kg: number;

    public baseProduct: Product;
    public formula: FormulaComponent[] = [];
    public formula_ancillary: FormulaComponent[] = [];

    public components: BatchComponent[] = [];
    public components_ancillary: BatchComponent[] = [];

    public coat: Coat;
    public substrate: Substrate;
    public system: VariantSystem;
    public product_type: ProductType;
    public applicationTechnique: ApplicationTechnique;
    public is_inside: boolean;

    public is_discarded: boolean = false;
    public is_locked: boolean = false;
    public is_matchingBatch: boolean = false;
    public is_fromPreMix: boolean = false;

    public variant_id: Variant;
    public job_id: number;
    public colour_id: number;
    public colourMatch_id: number;
    public bodyshop_id: number;
    public is_split: boolean;

    public ts_created: Date;
    public user_id_created: number;
    public user_id_assigned: number;
    public user_id_modified: number;
    public user_created: User;
    public user_assigned: User;

    public split_from: number;
    public recalculations: BatchOvershot[] = [];
    public panelSelection: any;

    constructor(formula?: FormulaComponent[],
        product_type?: ProductType,
        coat?: Coat,
        system?: VariantSystem) {

        this.product_type = product_type;
        this.coat = coat;
        this.system = system;

        if (formula) {
            let newFormula = [];

            for (let component of formula) {
                let newComponent = new FormulaComponent();
                newComponent.product = component.product;
                newComponent.ratio = component.ratio;
                newComponent.error = component.error;
                newComponent.amount_kg = component.amount_kg;

                newFormula.push(newComponent);
            }

            this.formula = newFormula;
        }

    }

    updateValues(newBatch: Batch) {

    }

    getFormulaFromComponents(): FormulaComponent[] {
        let frml: FormulaComponent[] = [];
        let total = this.getTotalBaseQuantityInKg();

        for (let bc of this.components) {
            let fc = new FormulaComponent();
            fc.product = bc.product;
            fc.ratio = bc.quantity_kg / total;

            frml.push(fc);
        }

        return frml.sort((a: FormulaComponent, b: FormulaComponent) => {
            return b.ratio - a.ratio;
        });
    }

    hasAncillariesConsumed() {
        for (let component of this.components_ancillary) {
            if (component.quantity_kg > 0)
                return true;
        }

        return false;
    }

    isEditable() {
        return !this.is_locked && !this.is_discarded;
    }

    getAllFormulaComponents() {
        let all: FormulaComponent[] = [];

        for (let cmp of this.formula) {
            all.push(cmp);
        }
        for (let cmp of this.formula_ancillary) {
            all.push(cmp);
        }

        return all;
    }

    getAllComponents() {
        let all: BatchComponent[] = [];

        for (let cmp of this.components) {
            all.push(cmp);
        }
        for (let cmp of this.components_ancillary) {
            all.push(cmp);
        }

        return all;
    }

    initComponents() {
        this.components = [];

        for (let component of this.formula) {
            let newComp = new BatchComponent();
            newComp.product = component.product;
            newComp.quantity_kg = 0.0;

            this.components.push(newComp);
        }
    }

    getTarget(fComponent: FormulaComponent): number {
        if (fComponent) {
            let isBase = this.formula.includes(fComponent);
            let bComponent = isBase ? this.getComponentInBaseByProductId(fComponent.product.id) : this.getComponentInAncillariesByProductId(fComponent.product.id);

            let target = 0.0;

            //check if it is from formula or formula_ancillary
            if (isBase) {
                target = fComponent.ratio * this.quantity_target_kg;
            }
            else {
                target = this.getTotalBaseQuantityInKg() * fComponent.ratio;
            }

            return Toolkit.precision(target, 5);
        }
    }

    getTotalQuantityInKg() {
        let total = this.getTotalBaseQuantityInKg();
        for (let component of this.components_ancillary) {
            total += component.quantity_kg;
        }

        return total;
    }


    getTotalBaseQuantityInKg() {
        let total = 0.0;
        for (let component of this.components) {
            total += component.quantity_kg;
        }

        return total;
    }

    getTotalAncillariesQuantityInKg() {
        let total = 0.0;
        for (let component of this.components_ancillary) {
            total += component.quantity_kg;
        }

        return total;
    }

    getFormulaComponentByProductId(productId: string): FormulaComponent {
        let component = this.getFormulaComponentInBaseByProductId(productId);

        if (!component) {
            component = this.getFormulaComponentInAncillariesByProductId(productId);
        }

        return component;
    }

    getFormulaComponentInAncillariesByProductId(productId: string): FormulaComponent {
        let component = undefined;

        for (let item of this.formula_ancillary) {
            if (item.product && item.product.id == productId) {
                component = item;
                break;
            }
        }

        return component;
    }

    getFormulaComponentInBaseByProductId(productId: string): FormulaComponent {
        let component = undefined;

        for (let item of this.formula) {
            if (item.product && item.product.id == productId) {
                component = item;
                break;
            }
        }

        return component;
    }

    getComponentByProductId(productId: string): BatchComponent {
        let component = this.getComponentInBaseByProductId(productId);

        //if not found, look in ancillaries
        if (!component) {
            component = this.getComponentInAncillariesByProductId(productId);
        }

        return component;
    }

    getComponentInBaseByProductId(productId: string): BatchComponent {
        let component = undefined;

        for (let item of this.components) {
            if (item.product && item.product.id == productId) {
                component = item;
                break;
            }
        }

        return component;
    }

    getComponentInAncillariesByProductId(productId: string): BatchComponent {
        let component = undefined;

        for (let item of this.components_ancillary) {
            if (item.product && item.product.id == productId) {
                component = item;
                break;
            }
        }

        return component;
    }

    getFormulaComponent(bComponent: BatchComponent): FormulaComponent {
        let isBase = this.components.includes(bComponent);
        return isBase ? this.getFormulaComponentInBaseByProductId(bComponent.product.id) : this.getFormulaComponentInAncillariesByProductId(bComponent.product.id);
    }

    getComponent(fComponent: FormulaComponent): BatchComponent {
        let isBase = this.formula.includes(fComponent);
        let bc = isBase ? this.getComponentInBaseByProductId(fComponent.product.id) : this.getComponentInAncillariesByProductId(fComponent.product.id);

        return bc;
    }

    getTotalCost() {
        let total = 0.0;

        //loop through components
        for (let component of this.components) {
            if (component.quantity_kg > 0)
                total += component.quantity_kg * component.cost_per_kg;
        }

        //loop through ancillaries
        for (let component of this.components_ancillary) {
            if (component.quantity_kg > 0)
                total += component.quantity_kg * component.cost_per_kg;
        }

        return Toolkit.precision(total, 2);
    }

    getMinimumAmountTotal() {
        if (this.formula.length == 0)
            return 0.0;


        ////////////////////////////////////////////////
        // Minimum based on Formula
        ////////////////////////////////////////////////

        //find biggest and smallest ratio
        let smallest = this.formula[0];
        let biggest = this.formula[0];
        for (let component of this.formula) {
            if (component.ratio > 0 && smallest.ratio > component.ratio) {
                smallest = component;
            }
            if (biggest.ratio < component.ratio) {
                biggest = component;
            }
        }

        //scaling vs 0.01g of minimum amount
        let formulaScalingFactor = 0.00001 / smallest.ratio;

        let formulaMinimum = 0.0;
        for (let component of this.formula) {
            formulaMinimum += component.ratio * formulaScalingFactor;
        }

        ////////////////////////////////////////////////
        // Minimum based on Consumption
        ////////////////////////////////////////////////

        let consumptionMinimum = 0.0;
        for (let component of this.components) {
            let formulaComponent = this.getFormulaComponentByProductId(component.product.id);

            if (!formulaComponent)
                continue;

            if (formulaComponent.ratio == 0)
                continue;

            let newConsumptionMinimum = component.quantity_kg / formulaComponent.ratio;

            if (newConsumptionMinimum > consumptionMinimum) {
                consumptionMinimum = newConsumptionMinimum;
            }
        }

        return formulaMinimum >= consumptionMinimum ? Toolkit.precision(formulaMinimum, 5) : Toolkit.precision(consumptionMinimum, 5);
    }

}

export class BatchComponent {
    public product: Product;
    public quantity_kg: number;
    public tare: number = 0.0;
    public cost_per_kg: number;
}

export class BatchOvershot {
    public timestamp: Date = new Date();
    public product: Product;
    public target_kg: number;
    public actual_kg: number;
    public user: User;
}

export class JobCustomProduct {
    public product: Product;
    public quantity: number;
    public unit_cost: number;
}

export class ProductSku {
    public pack_size: number;
    public code: string;
}

export class Product {
    public id;
    public code: string;
    public code_short: string;
    public kg_conversion: number;
    public name: string;
    public type: ProductType;
    public category: ProductCategory;
    public unit: Unit;
    public available_in: ProductType[] = [];
    public formula: object;
    public tracers: Tracer[] = [];
    public tracer: Tracer;
    public is_kansai: boolean;
    public is_undercoat: boolean;
    public skus: ProductSku[] = [];
    public manufacturer: string;
    public tinter_class: string;
    public substrates: Substrate[] = [];

    getLargestSku(): ProductSku {
        if (!this.skus || this.skus.length == 0)
            return null;

        let largest_sku = this.skus[0]
        for (let sku of this.skus) {
            if (sku.pack_size > largest_sku.pack_size) {
                largest_sku = sku;
            }
        }

        return largest_sku;
    }

    getSmallestSku(): ProductSku {
        if (!this.skus || this.skus.length == 0)
            return null;

        let smallest_sku = this.skus[0]
        for (let sku of this.skus) {
            if (sku.pack_size < smallest_sku.pack_size) {
                smallest_sku = sku;
            }
        }

        return smallest_sku;
    }

    hasTracer() {
        return this.tracers && this.tracers.length > 0;
    }

    isTracer() {
        return this.tracer != null;
    }

    isAvailableIn(ptype: ProductType): boolean {
        for (let productType of this.available_in) {
            if (!(ptype && productType)) {
                return false;
            }

            if (ptype.id == productType.id) {
                return true;
            }
        }

        return false;
    }

    isAvailableForSubstrate(substrate: Substrate): boolean {
        if (substrate) {
            for (let substr of this.substrates) {
                if (substr.id == substrate.id) {
                    return true;
                }
            }
        }

        return false;
    }

    getApplicationTechniqueIds() {
        let appTechs = new Set();

        if (this.formula) {
            for (let substrate in this.formula) {
                for (let appTech in this.formula[substrate]) {
                    appTechs.add(appTech);
                }
            }
        }

        return Array.from(appTechs);
    }
}

export class Tracer {
    public product: Product;
    public dilutionFactor: number;
}

export class ProductType {
    public id: string;
    public code: string;
    public name: string;
    public logo_url;
    public coats_variant: Coat[] = [];

}


export class ProductCategory {
    public id: string;
    public name: string;
}

export class InventoryItem {
    public id: number;
    public product: Product;
    public type: string;
    public unit_cost: number;
    public active: boolean;
    public stock_level_current: number;
    public stock_level_alert: number;
    public is_consume: boolean;
}

export class InventoryConsumption {
    public id: number;
    public batch_id: number;
    public bodyshop_id: number;
    public items: InventoryConsumptionItem[] = [];
    public tenant_id: number;
    public timestamp: Date;
    public type: string;
    public user_id: number
}

export class InventoryConsumptionItem {
    public quantity: number;
    public quantity_kg: number;
    public unit: Unit;
    public inventoryItem_id: number;
    public product: Product;
    public value: number;
}

export class CarManufacturer {
    public id: number;
    public name: string;
    public logo_url: string;

    public models: CarModel[] = [];

    getModelById(id: number) {
        for (let model of this.models) {
            if (model.id == id) {
                return model;
            }
        }

        return undefined;
    }

    sortModelsByName() {
        this.models.sort((a: CarModel, b: CarModel) => {
            if (a.name > b.name) {
                return 1;
            }
            else if (a.name < b.name) {
                return -1;
            }
            else {
                return 0;
            }
        });
    }
}

export class CarModel {
    public id: number;
    public name: string;
    public car_manufacturer: CarManufacturer;
    public is_generic: boolean;
    public consumption: object;
    public estimates: ConsumptionEstimatesSet = new ConsumptionEstimatesSet();
}

export class ApplicationTechnique {
    public id: string;
    public name: string;
    public is_default: string;
}

export class CarPanel {
    public id: string;
    public name: string;
    public is_outside: boolean;
    public surface_in: boolean;
    public surface_out: boolean;
    public default_substrate: Substrate;
}

export class CoatClass {
    public id: string;
    public name: string;
}

export class MixRatioSet {
    public ratios: MixRatio[] = [];

    isEmpty() {
        return this.ratios.length == 0;
    }

    filter(key: string, value: any): MixRatioSet {
        if (value == null) {
            return this;
        }

        let ratioSet = new MixRatioSet();

        for (let ratio of this.ratios) {
            if (ratio[key] != undefined) {
                let equals = false;
                if (typeof (ratio[key]) === 'object' && 'id' in ratio[key]) {
                    equals = ratio[key]['id'] == value['id'];
                }
                else {
                    equals = ratio[key] == value;
                }

                if (equals) {
                    ratioSet.ratios.push(ratio);
                }
            }
        }

        return ratioSet;
    }

    productCategory(product_category: ProductCategory): MixRatioSet {
        return this.filter('product_category', product_category);
    }

    applicationTechnique(application_technique: ApplicationTechnique): MixRatioSet {
        return this.filter('application_technique', application_technique);
    }

    baseProduct(base_product: Product): MixRatioSet {
        return this.filter('base_product', base_product);
    }

    stages(stages: number) {
        return this.filter('stages', stages);
    }

    coat(coat: Coat): MixRatioSet {
        return this.filter('coat', coat);
    }

    coatClass(coat_class: CoatClass): MixRatioSet {
        return this.filter('coat_class', coat_class);
    }

    isInside(is_inside: boolean): MixRatioSet {
        return this.filter('is_inside', is_inside);
    }

    productType(product_type: ProductType): MixRatioSet {
        return this.filter('product_type', product_type);
    }

    substrate(substrate: Substrate): MixRatioSet {
        return this.filter('substrate', substrate);
    }

    wbEv_has539(wbEv_has539: boolean): MixRatioSet {
        return this.filter('wbEv_has539', wbEv_has539);
    }

    pg80_hardener(hardener: Product) {
        if (!hardener) {
            return this;
        }

        let ratioSet = new MixRatioSet();

        for (let ratio of this.ratios) {
            if (ratio.pg80_hardener) {
                for (let product of ratio.pg80_hardener) {
                    if (product.id == hardener.id) {
                        ratioSet.ratios.push(ratio);
                    }
                }
            }
        }

        return ratioSet;
    }

    getProductCategoriesForAncillaries(): ProductCategory[] {
        let categories = {};

        for (let ratio of this.ratios) {
            categories[ratio.product_category.id] = ratio.product_category;
        }

        let result: ProductCategory[] = [];
        for (let key in categories) {
            result.push(categories[key]);
        }

        return result.sort((a: ProductCategory, b: ProductCategory) => {
            if (a.id < b.id)
                return -1;
            if (a.id > b.id)
                return 1;
            return 0;
        });
    }

    getProductsForCategoryId(category_id: string): Product[] {
        let products = {}

        for (let ratio of this.ratios) {
            if (ratio.product_category.id == category_id) {
                for (let p of ratio.products) {
                    products[p.id] = p;
                }
            }
        }

        let returnValue: Product[] = [];

        for (let product_code in products) {
            returnValue.push(products[product_code])
        }

        return returnValue.sort((a: Product, b: Product) => {
            if (a.id < b.id)
                return -1;
            if (a.id > b.id)
                return 1;
            return 0;
        })
    }

    hasCategory(category_id: string) {
        for (let ratio of this.ratios) {
            if (ratio.product_category.id == category_id) {
                return ratio.product_category;
            }
        }

        return;
    }

    hasFilterCriteria(filter_name: string) {
        for (let ratio of this.ratios) {
            if (filter_name in ratio) {
                return true;
            }
        }

        return false;
    }

    getSubstrates() {
        let substrates = {};

        for (let ratio of this.ratios) {
            if (ratio.substrate) {
                substrates[ratio.substrate.id] = ratio.substrate;
            }
        }

        let retValues: Substrate[] = [];
        for (let key in substrates) {
            retValues.push(substrates[key]);
        }

        return retValues.sort((a: Substrate, b: Substrate) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
    }

    getApplicationTechniques() {
        let appTechs = {};

        for (let ratio of this.ratios) {
            if (ratio.application_technique) {
                appTechs[ratio.application_technique.id] = ratio.application_technique;
            }
        }

        let retValues: ApplicationTechnique[] = [];
        for (let key in appTechs) {
            retValues.push(appTechs[key]);
        }

        return retValues.sort((a: ApplicationTechnique, b: ApplicationTechnique) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
    }
}

export class MixRatio {
    public id: number;
    public product_category: ProductCategory;

    public application_technique: ApplicationTechnique;
    public base_product: Product;
    public stages: number;
    public coat: Coat;
    public coat_class: CoatClass;
    public is_inside: boolean;
    public product_type: ProductType;
    public substrate: Substrate;
    public pg80_hardener: Product[];
    public wbEv_has539: boolean;

    public ratio: number;
    public error: number;
    public products: Product[];

    hasProduct(product: Product) {
        for (let prod of this.products) {
            if (product.id == prod.id) {
                return product;
            }
        }

        return false;
    }
}

export class ScaleDataPoint {
    public weight: number;
    public timestamp: Date;
    public target: ScaleTarget;
}

export class ScaleTarget {
    public weight: number = 0.0;
    public error: number = 0.0;
}


export class ConsumptionEstimatesSet {
    private estimates: ConsumptionEstimate[] = [];

    public isEmpty() {
        return !this.estimates || this.estimates.length == 0;
    }

    public size() {
        return this.estimates.length;
    }

    public getEstimates() {
        return this.estimates;
    }

    public push(estimate: ConsumptionEstimate) {
        this.estimates.push(estimate);
    }

    public getFirstEstimate(): number {
        if (this.size() > 0) {
            return this.estimates[0].estimate_kg;
        }

        return 0.0;
    }

    public getAverageEstimate(): number {
        let sum = 0.0;
        if (this.size() > 0) {
            for(let estimate of this.estimates){
                sum += estimate.estimate_kg;
            }
            return sum / this.estimates.length;
        }

        return 0.0;
    }

    public getBiggestEstimate(): number {
        if (this.size() > 0) {
            let biggest = this.estimates[0].estimate_kg;

            for(let estimate of this.estimates){
                if(estimate.estimate_kg > biggest){
                    biggest = estimate.estimate_kg;
                }
            }
            return this.estimates[0].estimate_kg;
        }

        return 0.0;
    }

    public getSmallestEstimate(): number {
        if (this.size() > 0) {
            let smallest = this.estimates[0].estimate_kg;

            for(let estimate of this.estimates){
                if(estimate.estimate_kg < smallest){
                    smallest = estimate.estimate_kg;
                }
            }
            return this.estimates[0].estimate_kg;
        }

        return 0.0;
    }

    public panel(panel: CarPanel): ConsumptionEstimatesSet {
        let set = new ConsumptionEstimatesSet();

        for (let estimate of this.estimates) {
            if (estimate.panel && panel) {
                if (estimate.panel.id == panel.id) {
                    set.push(estimate);
                }
            }
        }

        return set;
    }

    public is_inside(is_inside: boolean): ConsumptionEstimatesSet {
        let set = new ConsumptionEstimatesSet();

        for (let estimate of this.estimates) {
            if (estimate.is_inside == is_inside) {
                set.push(estimate);
            }
        }

        return set;
    }

    public repair(repair: RepairType): ConsumptionEstimatesSet {
        let set = new ConsumptionEstimatesSet();

        for (let estimate of this.estimates) {
            if (estimate.repair && repair) {
                if (estimate.repair.id == repair.id) {
                    set.push(estimate);
                }
            }
        }

        return set;
    }

    public coat(coat: Coat): ConsumptionEstimatesSet {
        let set = new ConsumptionEstimatesSet();

        for (let estimate of this.estimates) {
            if (estimate.coat && coat) {
                if (estimate.coat.id == coat.id) {
                    set.push(estimate);
                }
            }
        }

        return set;
    }

    public product_type(product_type: ProductType): ConsumptionEstimatesSet {
        let set = new ConsumptionEstimatesSet();

        for (let estimate of this.estimates) {
            if (estimate.product_type && product_type) {
                if (estimate.product_type.id == product_type.id) {
                    set.push(estimate);
                }
            }
        }

        return set;
    }

    public application_technique(application_technique: ApplicationTechnique): ConsumptionEstimatesSet {
        let set = new ConsumptionEstimatesSet();

        for (let estimate of this.estimates) {
            if (estimate.application_technique && application_technique) {
                if (estimate.application_technique.id == application_technique.id) {
                    set.push(estimate);
                }
            }
        }

        return set;
    }
}

export class ConsumptionEstimate {
    public panel: CarPanel;
    public is_inside: boolean;
    public repair: RepairType;
    public coat: Coat;
    public product_type: ProductType;
    public application_technique: ApplicationTechnique;
    public estimate_kg: number;
}

export class MaterialRequest {
    public id: number;
    public bodyshop: Bodyshop;
    public bodyshop_id: number;
    public date_created: Date;
    public date_modified: Date;
    public date_deleted: Date;
    public date_target_delivery: Date;
    public user_created: User;
    public user_created_id: number;
    public user_modified: User;
    public user_modified_id: number;
    public user_deleted: User;
    public user_deleted_id: number;
    public is_locked: boolean;
    public is_deleted: boolean;
    public items: MaterialRequestItem[] = [];

    public getQuantityCount(): number {
        let count = 0;

        for (let item of this.items) {
            count += item.quantity;
        }

        return count;
    }

    public getSkuCount(): number {
        return this.items.length;
    }

    public getProductCount(): number {
        let productSet = new Set();

        for (let item of this.items) {
            productSet.add(item.product.id);
        }

        return productSet.size;
    }

    public getItemByProductAndSku(product:Product, sku:ProductSku):MaterialRequestItem{
        for(let item of this.items){
            if(item.product.id == product.id){
                if(item.sku.code == sku.code && item.sku.pack_size == sku.pack_size){
                    return item;
                }
            }
        }

        return null;
    }
}

export class MaterialRequestItem {
    public product: Product;
    public sku: ProductSku;
    public quantity: number;
}


export class Order {
    public id: number;
    public tenant: Tenant;
    public tenant_id: number;
    public currency:Currency;
    public currency_id:string;
    public date_created: Date;
    public date_modified: Date;
    public date_target_delivery: Date;
    public user_created: User;
    public user_created_id: number;
    public user_modified: User;
    public user_modified_id: number;
    public is_locked: boolean;
    public is_submitted: boolean;
    public is_processed: boolean;
    public is_delivered: boolean;
    public is_deleted: boolean;
    public items: OrderItem[] = [];

    public isPriceAvailableForAllItems():boolean{
        for(let item of this.items){
            if(item.quantity > 0 && item.unit_price == null){
                return false;
            }
        }

        return true;
    }

    public getTotalPrice():number{
        let total = 0.0;

        for(let item of this.items){
            if(item.quantity > 0){
                total += item.quantity * item.unit_price;
            }
        }

        return total;
    }

    public getTotalKg():number{
        let total = 0.0;

        for(let item of this.items){
            if(item.quantity > 0){
                let kg = item.sku.pack_size * item.product.kg_conversion;
                total += item.quantity * kg;
            }
        }

        return total;
    }

    public getQuantityCount(): number {
        let count = 0;

        for (let item of this.items) {
            count += item.quantity;
        }

        return count;
    }

    public getSkuCount(): number {
        return this.items.length;
    }

    public getProductCount(): number {
        let productSet = new Set();

        for (let item of this.items) {
            productSet.add(item.product.id);
        }

        return productSet.size;
    }

    getItemsForProduct(product: Product): PriceListItem[] {
        let items = [];

        for (let item of this.items) {
            if (item.product.id == product.id) {
                items.push(item);
            }
        }

        return items.sort((a: PriceListItem, b: PriceListItem) => {
            if (a.sku.pack_size > b.sku.pack_size)
                return 1;
            if (a.sku.pack_size < b.sku.pack_size)
                return -1;
            return 0;
        });
    }
}

export class OrderItem {
    public product: Product;
    public sku: ProductSku;
    public quantity: number;
    public unit_price: number;
}

export class PriceList {
    public user_created_id: number;
    public user_created: User;
    public ts_created: Date;
    public tenant_id: number;
    public tenant: Tenant;
    public items: PriceListItem[] = [];
    public currency_id: string;
    public currency: Currency;

    getPriceListItem(product: Product, sku: ProductSku): PriceListItem {
        for (let item of this.items) {
            if (item.product.id == product.id) {
                if (item.sku.code == sku.code && item.sku.pack_size == sku.pack_size) {
                    return item;
                }
            }
        }

        return null;
    }

    getPriceListItemsForProduct(product: Product): PriceListItem[] {
        let items = [];

        for (let item of this.items) {
            if (item.product.id == product.id) {
                items.push(item);
            }
        }

        return items.sort((a: PriceListItem, b: PriceListItem) => {
            if (a.sku.pack_size > b.sku.pack_size)
                return 1;
            if (a.sku.pack_size < b.sku.pack_size)
                return -1;
            return 0;
        });
    }

    getPricePerKgForTypeAndCategory() {
        let stats = {
            'kg': 0.0,
            'price': 0.0,
            'types': {},
            'categories': {}
        };

        for (let item of this.items) {
            if (item.product.kg_conversion) {
                let kg = item.sku.pack_size * item.product.kg_conversion;
                let price = item.price;
                let type_key = '' + item.product.type.id;
                let category_key = '' + item.product.category.id;

                if (!stats['types'][type_key]) {
                    stats['types'][type_key] = {
                        'kg': 0.0,
                        'price': 0.0,
                        'categories': {}
                    };
                }                

                if (!stats['categories'][category_key]) {
                    stats['categories'][category_key] = {
                        'kg': 0.0,
                        'price': 0.0,
                        'types': {}
                    };
                }

                if (!stats['types'][type_key]['categories'][category_key]) {
                    stats['types'][type_key]['categories'][category_key] = {
                        'kg': 0.0,
                        'price': 0.0
                    };
                }

                if (!stats['categories'][category_key]['types'][type_key]) {
                    stats['categories'][category_key]['types'][type_key] = {
                        'kg': 0.0,
                        'price': 0.0
                    };
                }

                if (price && kg) {
                    stats['kg'] += kg;
                    stats['price'] += price;
                    stats['types'][type_key]['kg'] += kg;
                    stats['types'][type_key]['price'] += price;
                    stats['types'][type_key]['categories'][category_key]['kg'] += kg;
                    stats['types'][type_key]['categories'][category_key]['price'] += price;
                    stats['categories'][category_key]['kg'] += kg;
                    stats['categories'][category_key]['price'] += price;
                    stats['categories'][category_key]['types'][type_key]['kg'] += kg;
                    stats['categories'][category_key]['types'][type_key]['price'] += price;
                }

            }
        }

        return stats;
    }
}

export class PriceListItem {
    public product: Product;
    public sku: ProductSku;
    public price: number;
    public lead_time_days: number;
}