import { Injectable, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public errors = [];
  public onError = new Subject();
  
  constructor() { }

  reportError(error){
    this.errors.push(error);
    this.onError.next(error);
  }

  dismissAll(){
    this.errors = [];
  }
}
