<div id="container">
  <div id="title">
    An Error has Occurred
  </div>
  <div id="error">
    <div class="error-message">{{error_message}}</div>
    <br>
    <table>
      <tr *ngFor="let row of rows">
        <td class="error-row-key">{{row.key}}</td>
        <td>{{row.value}}</td>
      </tr>
    </table>
  </div>
  <div id="buttons">
    <button mat-raised-button color="primary" (click)="clickOk()" class="large-button" #secondaryButton> OK </button>
  </div>
  <div class="debugLogHttpMore" *ngIf="!_showHttpLog" (click)="_showHttpLog = true">
    <div>Show More...</div>
  </div>
  <div class="debugLogHttpMore" *ngIf="_showHttpLog" (click)="_showHttpLog = false">
    <div>Show Less...</div>
  </div>
  <div id="debugLogHttp" *ngIf="_showHttpLog">
    <table>
      <tr *ngFor="let logEntry of dataService.debugInfo">
        <td>{{logEntry.timestamp | date:'yyyy-MM-dd, hh:mm:ss.SSS'}}</td>
        <td>{{logEntry.method}}</td>
        <td>{{logEntry.url}}</td>
      </tr>
    </table>
  </div>
</div>