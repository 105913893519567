import { BehaviorSubject } from 'rxjs';
import { SubstrateService } from './substrate.service';
import { VariantService } from './variant.service';
import { CoatService } from './coat.service';
import { ProductService } from './product.service';
import { Batch, MixRatioSet, MixRatio } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MixRatioService {
  private api_endpoint = '/mix-ratio';
  private full_api_url;

  private separator_product = "/product/";
  private separator_type = "/type/";

  public ratios: MixRatioSet = new MixRatioSet();
  public initialised = new BehaviorSubject(false);

  constructor(
    private dataService: DataService,
    private productService: ProductService,
    private coatService: CoatService,
    private substrateService: SubstrateService
  ) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }


  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);

    this.productService.initialised.subscribe(stateProduct => {
      if(stateProduct){
        this.coatService.initialised.subscribe(stateCoat => {
          if(stateCoat){
            this.substrateService.initialised.subscribe(stateSubstrate => {
              if(stateSubstrate){
                //get ratios
                this.dataService.postRequest(this.full_api_url, {}).subscribe(response => {
                  this.ratios = new MixRatioSet();
                  this.ratios.ratios = [];
            
                  if (response && response['data']) {
                    for (let resRatio of response['data']) {
                      let ratio = this.buildRatio(resRatio);

                      if(ratio){
                        this.ratios.ratios.push(ratio);
                      }
                    }
                  }
                  
                  this.initialised.next(true);
                });
              }
            });
          }
        })
      }
    })
  }



  getMixRatiosforBatch(batch: Batch):MixRatioSet {
    let ratios:MixRatioSet;
    

    if (batch.baseProduct) {
      ratios = this.ratios.baseProduct(batch.baseProduct);
    }
    else {
      ratios = this.ratios.productType(batch.product_type);

      if (batch.product_type.id != 'pg80') {
        ratios = ratios.coat(batch.coat);

        if (batch.system) {
          ratios = ratios.stages(batch.system.stages);

          if (batch.system[batch.coat.id]) {
            ratios.coatClass(batch.system[batch.coat.id]);
          }
        }
      }

      //in case there is no ratios found for the coat
      //check if there is a ratio found for the first product in it
      //this way ELS and undercoat in cardea do not have to be recorded
      //in mix ratios DB twice (once as primer and once as ELS/under)
      if(ratios.ratios.length == 0 && batch.formula){
        //loop over and use first ratios found
        for(let component of batch.formula){
          ratios = this.ratios.baseProduct(component.product);
          if(ratios.ratios.length > 0){
            break;
          }
        }
      }
    }

    return ratios;
  }

  buildRatio(resRatio): MixRatio {
    let ratio = new MixRatio();
    ratio.id = resRatio['id'];
    ratio.product_category = this.productService.getProductCategoryById(resRatio['product_category']);
    ratio.ratio = resRatio['ratio'];
    ratio.error = resRatio['error'];

    if ('application_technique' in resRatio && resRatio['application_technique']) {
      ratio.application_technique = this.coatService.getApplicationTechniqueById(resRatio['application_technique']);
    }

    if ('base_product' in resRatio && resRatio['base_product']) {
      ratio.base_product = this.productService.getProductById(resRatio['base_product']);
    }

    if ('product_type' in resRatio && resRatio['product_type']) {
      ratio.product_type = this.productService.getProductTypeById(resRatio['product_type']);
    }

    if ('stages' in resRatio) {
      ratio.stages = resRatio['stages'];
    }

    if ('coat' in resRatio && resRatio['coat']) {
      ratio.coat = this.coatService.getCoatById(resRatio['coat']);
    }

    if ('coat_class' && resRatio['coat_class']) {
      ratio.coat_class = this.coatService.getCoatClassById(resRatio['coat_class']);
    }

    if ('is_inside' in resRatio) {
      ratio.is_inside = resRatio['is_inside'];
    }

    if ('substrate' in resRatio && resRatio['substrate']) {
      ratio.substrate = this.substrateService.getSubstrateOffline(resRatio['substrate']);
    }

    if ('pg80' in resRatio && resRatio['pg80']) {
      if ('hardener' in resRatio['pg80']) {
        ratio.pg80_hardener = [];

        for (let p of resRatio['pg80']['hardener']) {
          let prod = this.productService.getProductById(p);

          if (prod) {
            ratio.pg80_hardener.push(prod);
          }
        }
      }
    }

    if ('wb-ev' in resRatio && resRatio['wb-ev']) {
      if ('has_539' in resRatio['wb-ev']) {
        ratio.wbEv_has539 = resRatio['wb-ev']['has_539'];
      }
    }


    if ('products' in resRatio && resRatio['products']) {
      ratio.products = [];

      for (let product of resRatio['products']) {
        let p = this.productService.getProductById(product);
        if (p) {
          ratio.products.push(p);
        }
      }
    }
    else {
      let ptype = ratio.base_product ? ratio.base_product.type : ratio.product_type;
      ratio.products = [];

      for (let product of this.productService.getProductsByTypeIdAndCategoryId(ptype.id, ratio.product_category.id)) {
        if (ratio.substrate) {
          if (product.isAvailableForSubstrate(ratio.substrate)) {
            ratio.products.push(product);
          }
        }
        else {
          ratio.products.push(product);
        }
      }
    }

    return ratio;
  }

}
