import { Toolkit } from '../../../toolkit/toolkit';
import { ColourService } from './colour.service';
import { ProductService } from './product.service';
import { Colour, ProductType, FormulaComponent, Variant, VariantCoat, Substrate, VariantCoatAttempt, VariantSystem, Batch, Bodyshop } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { CoatService } from './coat.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CountryService } from './country.service';
import { BodyshopService } from './bodyshop.service';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
  private api_endpoint_variant = '/variant';
  private full_api_url_variant;

  private api_endpoint_variant_check = '/variant-check';
  private full_api_url_variant_check;

  private api_endpoint_variant_list = '/variant-list';
  private full_api_url_variant_list;

  private api_endpoint_variant_similar = '/variant-similar';
  private full_api_url_variant_similar;

  private api_variant_delim_colour = "/colour/";
  private api_variant_delim_productType = "/product/";

  constructor(private dataService: DataService,
    private productService: ProductService,
    private coatService: CoatService,
    private colourService: ColourService,
    private countryService: CountryService,
    private bodyshopService: BodyshopService) {

    this.full_api_url_variant = dataService.getUrl() + this.api_endpoint_variant;
    this.full_api_url_variant_list = dataService.getUrl() + this.api_endpoint_variant_list;
    this.full_api_url_variant_check = dataService.getUrl() + this.api_endpoint_variant_check;
    this.full_api_url_variant_similar = dataService.getUrl() + this.api_endpoint_variant_similar;
  }

  getVariants(colour: Colour, productType?: ProductType, types?:string[]) {
    let requestUrl = this.full_api_url_variant_list;
    let requestBody = { 
      'scope': {}
    };

    if(colour){
      requestBody.scope['colour'] = colour.id;
    }

    if(productType){
      requestBody.scope['product_type'] = productType.id;
    }

    if(types){
      requestBody.scope['types'] = types;
    }


    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      let variants: Variant[] = [];

      if (response && response['data']) {
        for (let item of response['data']) {
          variants.push(this.buildVariant(item));
        }
      }

      return variants;
    });
  }

  getVariant(id: number) {
    let requestUrl = this.full_api_url_variant;
    requestUrl += "/" + id;

    return this.dataService.getRequest(requestUrl).map(response => {
      if (response) {
        return this.buildVariant(response);
      }
    });
  }

  storeVariant(variant: Variant) {
    let requestUrl = this.full_api_url_variant;
    let requestBody = this.buildRequestBody(variant);

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      if (response) {
        return this.buildVariant(response);
      }
    });
  }

  checkVariant(variant: Variant): Observable<Variant> {
    let requestUrl = this.full_api_url_variant_check;
    let requestBody = this.buildRequestBody(variant);

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      return this.buildVariant(response);
    });
  }

  getSimilarVariants(variant: Variant, margin?: number): Observable<Variant[]> {
    let requestUrl = this.full_api_url_variant_similar;
    let requestBody = {};

    if (margin){
      requestBody['margin'] = margin
    }

    if(variant.id){
      requestBody['variant_id'] = variant.id;
    }
    else{
      requestBody['variant'] = this.buildRequestBody(variant)
    }

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      let variants = [];
      if (response) {
        for (let resVariant of response['data']) {
          let newV = this.buildVariant(resVariant);

          if (newV) {
            variants.push(newV);
          }
        }
      }

      return variants;
    });
  }

  buildSystem(response: any): VariantSystem {
    let system = new VariantSystem();
    system.base = this.coatService.getCoatClassById(response['base']);
    system.top = this.coatService.getCoatClassById(response['top']);
    system.stages = response['stages'];
    system.has_els = response['has_els'];
    system.has_under = response['has_under'];

    return system;
  }

  buildVariant(response: object) {
    let variant = new Variant();
    variant.id = response['id'];
    variant.code = response['code'];
    variant.version = response['version'];
    variant.is_active = response['is_active'];
    variant.product_type = this.productService.getProductTypeById(response['product_type']);
    variant.colour = this.colourService.buildColour(response['colour']);
    variant.country = this.countryService.getCountryById(response['origin_country']);

    //if variant is embeded in colourMatch check for key property
    if (!variant.id && response['key']) {
      variant.id = response['key'];
    }

    if (response['system']) {
      variant.system = this.buildSystem(response['system']);
    }

    if (response['coats']) {
      variant.coats = [];

      for (let coatKey in response['coats']) {
        let vCoat = new VariantCoat();
        vCoat.coat = this.coatService.getCoatById(coatKey);
        vCoat.class = this.coatService.getCoatClassById(response['coats'][coatKey]['class']);

        if (response['coats'][coatKey]['origin_batch']){
          vCoat.origin_batch_id = response['coats'][coatKey]['origin_batch'];
        }

        if (response['coats'][coatKey] && response['coats'][coatKey]['formula']) {
          vCoat.formula = this.buildFormula(response['coats'][coatKey]['formula']);
        }
        else {
          vCoat.formula = [];
        }
        variant.coats.push(vCoat);
      }

      variant.coats = variant.sortFormulasByCoat();
    }

    //bodyshop info
    if (response['origin_bodyshop']) {
      variant.bodyshop = new Bodyshop();
      variant.bodyshop.id = response['origin_bodyshop'];
    }

    /*
    if (response['origin_bodyshop']) {
      this.bodyshopService.getBodyshopById(response['origin_bodyshop']).subscribe(bodyshop => {
        variant.bodyshop = bodyshop;
      },
        error => {
          //do nothing
        });
    }
    */

    return variant;
  }

  private buildFormula(responseFormula) {
    let formula = [];

    for (let responseCmp of responseFormula) {
      let fComponent = new FormulaComponent();
      fComponent.ratio = responseCmp['ratio'];
      fComponent.product = this.productService.getProductById(responseCmp['product']);

      formula.push(fComponent);
    }

    return formula;
  }


  buildRequestBody(variant: Variant) {
    let requestBody = {};
    
    if (variant.id)
      requestBody['id'] = variant.id;

    requestBody['name'] = variant.name;
    requestBody['version'] = variant.version;
    requestBody['is_active'] = variant.is_active;
    requestBody['colour'] = variant.colour ? variant.colour.id : null;
    requestBody['product_type'] = variant.product_type ? variant.product_type.id : null;

    requestBody['coats'] = {};
    for (let coat of variant.coats) {
      //skip empty coats
      if (coat.formula.length == 0)
        continue;

      requestBody['coats'][coat.coat.id] = {
        'formula': []
      };

      requestBody['coats'][coat.coat.id]['formula'] = [];
      for (let component of coat.formula) {
        let nComp = {};
        nComp['product'] = component.product.id;
        nComp['ratio'] = Toolkit.precision(component.ratio, 4);
        nComp['error'] = component.error ? Toolkit.precision(component.error, 4) : 0.0;
        requestBody['coats'][coat.coat.id]['formula'].push(nComp);
      }
    }


    //history
    /*
    requestBody['coats'][coat.coat.id]['history'] =[];
    for(let attempt of coat.history){
      let nAttempt = {};
      nAttempt['timestamp'] = attempt.timestamp.toISOString();

      for(let component of coat.formula){
        let nComp = {};
        nComp['product'] = component.product.id;
        nComp['ratio'] = Toolkit.precision(component.ratio, 2);
        nComp['error'] = component.error ? Toolkit.precision(component.error, 2) : 0.0;
        nAttempt['formula'].push(nComp);
      }

      requestBody['coats'][coat.coat.id]['history'].push(nAttempt);
    }
    */


    return requestBody;
  }
}
