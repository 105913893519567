import { CurrencyService } from './currency.service';
import { PriceList, PriceListItem, ProductSku } from './../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceListService {
  private api_endpoint = '/price-list';
  private full_api_url;

  constructor(public dataService: DataService,
    public currencyService: CurrencyService,
    public productService: ProductService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getPriceList(tenant_id: number): Observable<PriceList> {
    let requestUrl = this.full_api_url + "/" + tenant_id;

    return this.dataService.getRequest(requestUrl).map(response => {
      if (response && response['data']) {
        return this.buildPriceList(response['data']);
      }
      else {
        return null;
      }
    });
  }

  savePriceList(priceList: PriceList): Observable<PriceList> {
    let requestUrl = this.full_api_url;
    let requestBody = {
      'tenant': priceList.tenant ? priceList.tenant.id : priceList.tenant_id,
      'currency': priceList.currency ? priceList.currency.id : priceList.currency_id,
      'price_list': []
    };

    for (let pcItem of priceList.items) {
      if (!pcItem.price) {
        continue;
      }

      let item = {
        'product': pcItem.product.id,
        'sku': {
          'code': pcItem.sku.code,
          'pack_size': pcItem.sku.pack_size
        },
        'price': pcItem.price,
        'lead_time_days': pcItem.lead_time_days
      };

      requestBody.price_list.push(item);
    }

    return this.dataService.postRequest(requestUrl, requestBody).map(response => {
      if (response && response['data']) {
        return this.buildPriceList(response['data']);
      }
      else {
        return null;
      }
    });
  }

  buildPriceList(response): PriceList {
    let priceList: PriceList = null;

    if (response) {
      priceList = new PriceList();
      priceList.ts_created = new Date(response['ts_created']);
      priceList.user_created_id = response['user_created'];
      priceList.tenant_id = response['tenant'];
      priceList.currency_id = response['currency'];
      priceList.items = [];

      //get currency details
      this.currencyService.getCurrencyById(priceList.currency_id).subscribe(currency => {
        priceList.currency = currency;
      });

      priceList.items = this.getDefaultPriceListItems();


      //pupulate the entry
      for (let resItem of response['price_list']) {
        let product = this.productService.getProductById(resItem['product']);
        let sku = new ProductSku();
        sku.pack_size = resItem['sku']['pack_size'];
        sku.code = resItem['sku']['code'];

        let plItem = priceList.getPriceListItem(product, sku);

        //if not found, create 
        if (!plItem) {
          plItem = new PriceListItem();

          plItem.product = product;
          plItem.sku = sku;

          priceList.items.push(plItem);
        }

        plItem.lead_time_days = resItem['lead_time_days'];
        plItem.price = resItem['price'];
      }
    }

    return priceList;
  }

  getDefaultPriceListItems(): PriceListItem[] {
    let items = [];

    //fill with empty product/sku entries
    for (let product of this.productService.products) {
      for (let sku of product.skus) {
        let newPlItem = new PriceListItem();
        newPlItem.product = product;
        newPlItem.sku = sku;

        items.push(newPlItem);
      }
    }

    return items;
  }

}