import { User } from './../../../../model/model';
import { environment } from './../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router/';
import { UserService } from '../../../shared/services/user.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public invalidLogin = false;
  public _isAuthenticating = false;

  public _isForgotPassword = false;
  public _requestSent = false;

  public _password: string;
  public _email: string;

  public _isBeingRedirected: boolean = false;

  constructor(public authService: AuthService,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      this._isForgotPassword = params['loginAction'] ? true : false;
    });


    if (this.authService.isLoggedIn()) {
      if (this.userService.currentUser) {
        this.forkNavigation(this.userService.currentUser);
      }
      else {
        this.userService.refreshData();
        let subscription = this.userService.initialised.subscribe(state => {
          if (state) {
            this.forkNavigation(this.userService.currentUser);
            subscription.unsubscribe();
          }
        });
      }
    }
  }

  ngOnInit() {
  }

  getEnvironment() {
    return environment;
  }

  forkNavigation(currentUser: User) {
    if (environment.localhost) {
      this.navigate();
    }
    else {
      if (!environment.production) {
        if (!currentUser.is_allowed_preprod) {
          this._isBeingRedirected = true;
          setTimeout(() => {
            location.href = 'https://' + environment.production_host;
          }, 5000);

        }
        else {
          this.navigate();
        }
      }
      else {
        this.navigate();
      }
    }


  }

  navigate() {
    if (this.userService.currentUser.is_global) {
      this.router.navigate(['/app-global']);
    }
    else if (this.userService.currentUser.is_kansai) {
      this.router.navigate(['/app-kansai']);
    }
    else {
      this.router.navigate(['/app']);
    }
  }

  login() {
    if (!this._email || !this._password)
      return;

    if (this._email.trim().length == 0)
      return;

    if (this._password.trim().length == 0)
      return;

    this._isAuthenticating = true;

    if (this._email && this._password) {
      this.authService.login(this._email, this._password).subscribe(
        result => {
          this._isAuthenticating = false;
          this.invalidLogin = !result;
          //this.cacheService.refreshData();

          this._password = ""; //clear from memory
          this.userService.refreshData();
          let subscription = this.userService.initialised.subscribe(status => {
            if (status) {
              this.route.params.subscribe(params => {
                if (params['redir']) {
                  this.router.navigate([params['redir']]);
                }
                else {
                  this.forkNavigation(this.userService.currentUser);
                }
                subscription.unsubscribe();
              });
            }
          })


        },
        error => {
          this._isAuthenticating = false;
          this.invalidLogin = true;
          this.authService.logout();
        }
      )
    }
  }

  resetPassword() {
    if (this._email && this._email.trim().length > 0) {
      this._isAuthenticating = true;
      this._requestSent = true;

      this.authService.resetPassword(this._email).subscribe(response => {
        this._isAuthenticating = false;
      },
        error => {
          this._isAuthenticating = false;
          throw error;
        });
    }
  }
}
