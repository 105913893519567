import { ScaleTarget } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ScaleDataPoint } from '../../../model/model';

@Injectable({
  providedIn: 'root'
})
export class ScaleService {
  private full_api_url;
  private api_endpoint_read = '/read/';
  private api_endpoint_target = '/target/';
  private api_endpoint_tare = '/tare/';

  public config: any;
  private is_configured: boolean = false;
  public lastUpdate = new Date();
  public lastError = new Date();
  public lastValue: ScaleDataPoint;
  public onWeightUpdate: EventEmitter<ScaleDataPoint> = new EventEmitter<ScaleDataPoint>();
  public onStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public interval;
  public nextTarget: number = 0.0;
  public nextProductCode: string;
  public isSettingTargets: boolean = true;

  constructor(public dataService: DataService) {
    if (localStorage.getItem('scale_config')) {
      try {
        let storedConfig = localStorage.getItem('scale_config');
        let cfg = JSON.parse(atob(storedConfig));

        if (cfg['url']) {
          this.config = cfg;
          this.is_configured = true;
        }
      }
      catch {
        // do nothing
      }

      this.configure(this.config);
      this.startServer();
    }

    //check every second if scale is still sending valid data
    this.interval = setInterval(() => {
      //get difference in seconds
      let diff = (this.lastError.getTime() - this.lastUpdate.getTime()) / 1000;

      //consider disconnect if in error for more than 5 seconds
      this.onStatusChange.emit(diff < 5);
    }, 1000);
  }

  isConfigured() {
    return this.is_configured;
  }

  isRunning() {
    return this.interval != null && this.lastUpdate > this.lastError && (new Date()).getTime() - this.lastUpdate.getTime() < 2000;
  }

  configure(configuraiton: any) {
    if (this.isConfigValid(configuraiton)) {
      this.full_api_url = configuraiton.url;
      this.config = configuraiton;
      this.is_configured = true;
      localStorage.setItem('scale_config', btoa(JSON.stringify(this.config)));
    }
  }

  isConfigValid(configuraiton: any) {
    let isValid = true;

    if (!configuraiton) {
      isValid = false;
    }

    if (!configuraiton['url']) {
      isValid = false;
    }

    return isValid;
  }

  setTarget(target_kg: number, error_kg: number): Observable<any> {
    let requestUrl = this.full_api_url + this.api_endpoint_target;
    let requestBody = {
      'target': {
        'Weight': target_kg,
        'Error': error_kg
      }
    };

    return this.dataService.postRequest(requestUrl, requestBody);
  }

  setTare(): Observable<any> {
    let requestUrl = this.full_api_url + this.api_endpoint_tare;
    let requestBody = {};

    return this.dataService.postRequest(requestUrl, requestBody);
  }

  startServer() {
    this.interval = setInterval(() => {
      //only do the actual request if anyone is listening
      if (this.onWeightUpdate.observers.length > 0) {
        let requestBody = {}

        if (this.isSettingTargets && this.nextTarget) {
          requestBody['target'] = this.nextTarget;
          this.nextTarget = undefined;
        }

        if (this.isSettingTargets && this.nextProductCode) {
          requestBody['product_code'] = this.nextProductCode;
          this.nextProductCode = undefined;
        }

        
        this.dataService.postRequest(this.full_api_url + this.api_endpoint_read, requestBody).subscribe(response => {
          //console.log(response);

          let value = new ScaleDataPoint();
          value.weight = response['weight']
          value.timestamp = new Date(response['timestamp']);
          value.target = new ScaleTarget();
          
          if(response['target']){
            value.target.weight = response['target']['Weight'];
            value.target.error = response['target']['Error'];
          }

          this.onWeightUpdate.emit(value);


          this.lastValue = value;
          this.lastUpdate = new Date(response['timestamp']);
        },
          error => {
            this.lastError = new Date();
          });
          
      }
    }, 150);
  }

  stopServer() {
    clearInterval(this.interval);
    this.interval = undefined;
  }
}
