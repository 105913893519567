import { DialogErrorMessageComponent } from './../dialogs/dialog-error-message/dialog-error-message.component';
import { MatDialog } from '@angular/material';
import { ErrorService } from './../../../shared/services/error.service';
import { ActivatedRoute, Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router/';
import { AuthService } from '../../../shared/services/auth.service';
import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    private unauthLinks = [
        '/login',
        '',
        '/register',
        '/password-reset',
        '/forgot-password'
    ];


    @ViewChild('uiBlockDiv') uiBlockDiv: ElementRef;
    @ViewChild('uiBlockStorage') uiBlockStorage: ElementRef;

    constructor(public authService: AuthService,
        public errorService: ErrorService,
        public dialog: MatDialog,
        public route: ActivatedRoute,
        public router: Router) {

        //skip landing page and go straight to app
        //router.navigate(['/app']);


        router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.uiBlockDiv.nativeElement.innerHTML = this.uiBlockStorage.nativeElement.innerHTML;
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.uiBlockDiv.nativeElement.innerHTML = '';
            }
        });

        this.errorService.onError.subscribe(error => {
            if (this.dialog.openDialogs.length == 0) {
                if (!error['message'].includes('ExpressionChangedAfterItHasBeenCheckedError')) {
                    let dialogRef = this.dialog.open(DialogErrorMessageComponent, {
                        data: { 'error': error }
                    });
                }
            }

        });

    }

    isLoggedIn() {
        if (!this.authService.isLoggedIn()) {
            let url = this.router.routerState.snapshot.url;

            if (url) {
                let found = false;
                for (let link of this.unauthLinks) {
                    if (url.startsWith(link)) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    this.router.navigate(['/login', { redir: url }]);
                }
            }
        }
    }
}
