import { ErrorService } from './error.service';
import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
    constructor(private errorService:ErrorService){

    }
    
    handleError(error){
        this.errorService.reportError(error);
        console.log(error);
    }
}