import { ActivatedRoute } from '@angular/router/';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot  } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate{

  constructor(public auth: AuthService, 
    public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if(!this.auth.isLoggedIn()){
      this.router.navigate(['/login', {redir: state.url}]);
    }
   
    return this.auth.isLoggedIn();
  }
}
