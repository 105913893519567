import { ProductService } from './product.service';
import { CoatService } from './coat.service';
import { BatchService } from './batch.service';
import { VehicleService } from './vehicle.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { ColourService } from './colour.service';
import { VariantService } from './variant.service';
import { ColourMatch, Variant, Batch, Coat, Colour, MatchSeries, FormulaComponent, MatchAttempt } from '../../../model/model';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColourMatchService {
  private api_endpoint = '/colourmatch';
  private full_api_url;

  constructor(public dataService: DataService,
    public variantService: VariantService,
    public colourService: ColourService,
    private coatService: CoatService,
    private productService: ProductService,
    public batchService: BatchService,
    public vehicleService: VehicleService,
    public userService: UserService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getColourMatch(id: number) {
    if (id) {
      return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
        if (response) {
          return this.buildColourMatch(response);
        }
      });
    }
  }

  saveColourMatch(cMatch: ColourMatch): Observable<ColourMatch> {
    let request;
    let requestBody = this.buildRequestBody(cMatch);

    if (cMatch.id) {
      request = this.dataService.putRequest(this.full_api_url + "/" + cMatch.id, requestBody);
    }
    else {
      request = this.dataService.postRequest(this.full_api_url, requestBody);
    }

    return request.map(response => {
      return this.buildColourMatch(response);
    });
  }



  changeVariant(cMatch: ColourMatch, variant: Variant): Observable<ColourMatch> {
    let requestBody = {
      'target': 'variant',
      'action': 'switch_variant',
      'params': {
        'variant': this.variantService.buildRequestBody(variant)
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      return this.buildColourMatch(response);
    });
  }

  addNewMatchSeries(cMatch: ColourMatch, series: MatchSeries, batch: Batch): Observable<ColourMatch> {
    let requestBody = {
      'target': 'match_series',
      'action': 'add_match_series',
      'params': {
        'coat': series.coat.id,
        'batch': this.batchService.buildRequestBody(batch)
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      return this.buildColourMatch(response);
    });
  }

  addNewAttempt(cMatch: ColourMatch, series: MatchSeries, sourceBatch: Batch, target_kg: number): Observable<ColourMatch> {
    let requestBody = {
      'target': 'match_series',
      'action': 'add_match_attempt',
      'params': {
        'match_series_batch_id': series.batch_id,
        'source_batch_id': sourceBatch.id,
        'target_kg': target_kg
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      return this.buildColourMatch(response);
    });
  }

  promoteFormula(cMatch: ColourMatch, sourceBatch: Batch): Observable<ColourMatch> {
    let requestBody = {
      'target': 'variant',
      'action': 'update_formula',
      'params': {
        'source_batch_id': sourceBatch.id
      }
    };

    return this.dataService.patchRequest(this.full_api_url + "/" + cMatch.id, requestBody).map(response => {
      return this.buildColourMatch(response);
    });
  }


  discard(cMatch: ColourMatch) {
    return this.dataService.deleteRequest(this.full_api_url + "/" + cMatch.id).map(response => {
      return this.buildColourMatch(response);
    });
  }

  buildRequestBody(cMatch: ColourMatch) {
    let requestBody = {};

    if (cMatch.id)
      requestBody['id'] = cMatch.id;

    if (cMatch.colour.id) {
      requestBody['colour'] = cMatch.colour.id;
    }
    else {
      requestBody['colour_code'] = cMatch.colour_code;
      requestBody['colour_name'] = cMatch.colour_name;
    }

    if (cMatch.car_manufacturer) {
      requestBody['car_manufacturer'] = cMatch.car_manufacturer.id;
    }


    requestBody['bodyshop'] = this.userService.currentUser.currentBodyshop.id;
    requestBody['job'] = cMatch.job_id ? cMatch.job_id : null;
    requestBody['is_active'] = cMatch.is_active;
    requestBody['is_locked'] = cMatch.is_locked;
    requestBody['variant'] = cMatch.variant ? this.variantService.buildRequestBody(cMatch.variant) : null;
    requestBody['variant_init'] = cMatch.variant_init ? this.variantService.buildRequestBody(cMatch.variant_init) : null;

    requestBody['history'] = [];
    if (cMatch.history) {
      for (let item of cMatch.history) {
        let histItem = {};
        histItem['timestamp'] = item.timestamp.toISOString();
        histItem['variant'] = this.variantService.buildRequestBody(item.variant);

        requestBody['history'].push(histItem);
      }
    }

    return requestBody;
  }


  buildColourMatch(response) {
    console.log(response);

    let cMatch = new ColourMatch();
    if (response) {
      cMatch.id = response['id'];
      cMatch.job_id = response['job'];
      cMatch.is_active = response['is_active'];
      cMatch.is_locked = response['is_locked'];
      cMatch.variant = response['variant'] ? this.variantService.buildVariant(response['variant']) : undefined;

      if (response['car_manufacturer']) {
        cMatch.car_manufacturer = this.vehicleService.getCarManufacturerById(response['car_manufacturer']);
      }

      if (response['match_series']) {
        cMatch.matchSeries = [];

        for (let ms of response['match_series']) {
          let series = new MatchSeries();
          series.coat = this.coatService.getCoatById(ms['coat']);
          series.timestamp = new Date(ms['timestamp']);
          series.batch_id = ms['batch'];

          series.start = [];

          if (ms['start']) {
            for (let resComponent of ms['start']) {
              let component = new FormulaComponent();
              component.product = this.productService.getProductById(resComponent['product']);
              component.ratio = resComponent['ratio'];
              component.error = resComponent['error'];

              series.start.push(component);
            }
          }

          if (ms['attempts']) {
            series.attempts = [];
            for (let resAttempt of ms['attempts']) {
              let attempt = new MatchAttempt();
              attempt.batch_id = resAttempt['batch'];
              attempt.timestamp = new Date(resAttempt['timestamp']);

              series.attempts.push(attempt);
            }
          }

          cMatch.matchSeries.push(series);
        }

        cMatch.car_manufacturer = this.vehicleService.getCarManufacturerById(response['car_manufacturer']);
      }

      if (response['colour']) {
        cMatch.colour = this.colourService.buildColour(response['colour']);
        cMatch.colour_code = cMatch.colour.code;
        cMatch.colour_name = cMatch.colour.name;
      }
      else {
        cMatch.colour_code = response['colour_code'];
        cMatch.colour_name = response['colour_name'];

        cMatch.colour = new Colour();
        cMatch.colour.code = cMatch.colour_code;
        cMatch.colour.name = cMatch.colour_name;
        cMatch.colour.carManufacturer = cMatch.car_manufacturer;
      }

      if (cMatch.variant) {
        if (!cMatch.variant.colour || !cMatch.variant.colour.id) {
          cMatch.variant.colour = cMatch.colour;
        }
      }

      cMatch.batches_dev = response['dev_batches'];
      if (!cMatch.batches_dev)
        cMatch.batches_dev = {};

    }

    return cMatch;
  }
}
