import { DataService } from './data.service';
import { BehaviorSubject } from 'rxjs';
import { SecurityRight } from '../../../model/model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecurityRightService {
  private api_endpoint = '/security-right';
  private full_api_url;
  public rights: SecurityRight[] = []
  public lastRefresh;
  public initialised = new BehaviorSubject(false);

  constructor(private dataService: DataService) {
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  refreshData() {
    this.initialised.complete();
    this.initialised = new BehaviorSubject(false);
    this.rights = [];

    this.dataService.getRequest(this.full_api_url).subscribe(response => {
      if (response && response['data']) {

        for (let right of response['data']) {
          this.rights.push(this.buildSecurityRight(right));
        }

        this.lastRefresh = new Date();
        this.initialised.next(true);
      }
    });
  }

  getSecurityRightById(id: string) {
    for (let item of this.rights) {
      if (item.id == id) {
        return item;
      }
    }
  }

  buildSecurityRight(response): SecurityRight {
    let right: SecurityRight = undefined;

    if (response) {
      right = new SecurityRight();
      right.id = response['id'];
      right.domain = response['domain'];
      right.action = response['action'];
      right.scope_enduser = response['scope_enduser'];
      right.scope_kansaiCompany = response['scope_kansaiCompany'];
      right.scope_global = response['scope_global'];

      right.bodyshop_ids = [];
      right.tenant_id = response['tenant'];
      right.kansaiCompany_id = response['kansaiCompany'];
      right.is_globalAdmin = response['is_globalAdmin'];

      if (response['bodyshops']) {
        for (let bodyshopId of response['bodyshops']) {
          right.bodyshop_ids.push(bodyshopId);
        }
      }
    }

    return right;
  }
}