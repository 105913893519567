import { DataService } from './../../../../shared/services/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-error-message',
  templateUrl: './dialog-error-message.component.html',
  styleUrls: ['./dialog-error-message.component.css']
})
export class DialogErrorMessageComponent implements OnInit {
  public error;
  public error_message;
  public rows = [];

  public _showHttpLog = false;

  constructor(
    public dialogRef: MatDialogRef<DialogErrorMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataService:DataService) {
    this.error = data['error'];

    this.error_message = this.error.message;

    if (this.error instanceof HttpErrorResponse) {
      for (let key in this.error.error) {
        if (key == 'message') {
          this.rows.push({ 'key': key, 'value': this.error.error[key] });
        }
      }
    }
  }

  ngOnInit() {
  }

  clickOk() {
    this.dialogRef.close();
  }

}
