import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'kansai-root',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  public is_launching = false;

  constructor(public router:Router, public route:ActivatedRoute) { }

  ngOnInit() {
  }

  launchApp(){
    this.is_launching = true;
    this.router.navigate(['/login']);
  }

  getCurrentYear(){
    return (new Date()).getFullYear();
  }
}
