<div class="section-wrapper intro-section-wrapper">
  <div class="section intro-section">
    Privacy Statement
  </div>
</div>


<div class="section-wrapper">
  <div class="section">
    some Legal stuff
  </div>
</div>
