import { Observable } from 'rxjs/Observable';
import { CountryService } from './country.service';
import { Bodyshop } from '../../../model/model';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class BodyshopService {
  private api_endpoint = '/bodyshop';
  private full_api_url;

  constructor(private dataService:DataService,
    private countryService:CountryService) { 
    this.full_api_url = dataService.getUrl() + this.api_endpoint;
  }

  getBodyshopById(id:number):Observable<Bodyshop>{
    return this.dataService.getRequest(this.full_api_url + "/" + id).map(response => {
      return this.buildBodyshop(response);
    });
  }

  buildBodyshop(response):Bodyshop {
    let bshop = new Bodyshop();

    bshop.id = response['id'];
    bshop.tenant_id = response['tenant'];
    bshop.id_custom = response['id_custom'];
    bshop.name = response['name'];
    bshop.is_active = response['is_active'];
    bshop.is_deleted = response['is_deleted'];
    bshop.address = {};

    if (response['address']) {
      let address = response['address'];

      if (address['line_1'])
        bshop.address['line_1'] = address['line_1'];

      if (address['line_2'])
        bshop.address['line_2'] = address['line_2'];

      if (address['post_code'])
        bshop.address['post_code'] = address['post_code'];

      if (address['city'])
        bshop.address['city'] = address['city'];

      if (address['country'])
        this.countryService.initialised.subscribe(state => {
          if (state) {
            bshop.address['country'] = this.countryService.getCountryById(address['country']);
          }
        });


      if (address['coordinates'])
        bshop.address['coordinates'] = address['coordinates'];
    }
    
    return bshop;
  }
}
